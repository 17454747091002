import SuperTokens from "supertokens-auth-react";
import Multitenancy from "supertokens-auth-react/recipe/multitenancy";
import EmailVerification from "supertokens-auth-react/recipe/emailverification";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import Session from "supertokens-auth-react/recipe/session";
import { AllowedDomainsClaim } from 'supertokens-auth-react/recipe/multitenancy';

const getCssVarRgb = (varName: string) => {
    const hex = getComputedStyle(document.documentElement).getPropertyValue(varName).trim();

    let bigint = parseInt(hex.substring(1), 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;

    return `${r} ${g} ${b}`;
}


const style = `
    [data-supertokens] {
        font-family: Inter, Helvetica, "sans-serif" !important;
    }
    
    [data-supertokens~=container] {
        --palette-background: ${getCssVarRgb('--bs-body-bg')};
        --palette-inputBackground: ${getCssVarRgb('--bs-gray-100')};
        --palette-inputBorder: ${getCssVarRgb('--bs-gray-100')};
        --palette-primary: ${getCssVarRgb('--bs-primary')};
        --palette-primaryBorder: ${getCssVarRgb('--bs-primary')};
        --palette-textLink: ${getCssVarRgb('--bs-primary')};
        --palette-textTitle: 255, 255, 255;
        --palette-textLabel: 255, 255, 255;
        --palette-textPrimary: 255, 255, 255;
        --palette-error: 173, 46, 46;
        --palette-textInput: 169, 169, 169;
        
        font-family: Inter, Helvetica, 'sans-serif';
    }
    
    [data-supertokens~="superTokensBranding"] {
        display: none !important;
    }
    
    [data-supertokens~="divider"] {
        border-bottom-color: var(--bs-dark-light);
    }
    
    [data-supertokens~="input"] {
        font-family: Inter, Helvetica, 'sans-serif';
    }
    [data-supertokens~="headerSubtitle"] {
        display: none;
    }
`;

export const initSupertokens = () => {
    SuperTokens.init({
        appInfo: {
            // learn more about this on https://supertokens.com/docs/emailpassword/appinfo
            appName: "Sneakers",
            apiDomain: process.env.REACT_APP_API_DOMAIN!,
            websiteDomain: window.location.origin,
            apiBasePath: "/auth",
            websiteBasePath: "/auth",
        },
        recipeList: [
            Multitenancy.init(),
            EmailVerification.init({
                style: style,
                mode: "REQUIRED", // or "OPTIONAL"
            }),
            EmailPassword.init({
                style: style,
                signInAndUpFeature: {
                    signUpForm: {
                        formFields: [{
                            id: "name",
                            label: "Full name",
                            placeholder: "First name and last name"
                        }]
                    }
                },
                getRedirectionURL: async (context) => {
                    if (context.action === "SUCCESS") {
                        if (context.redirectToPath !== undefined) {
                            // we are navigating back to where the user was before they authenticated
                            return context.redirectToPath;
                        }
                        return "/dashboard";
                    }
                    return undefined;
                }
            }),
            Session.init({
                override: !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? undefined : {
                    functions: (oI) => ({
                        ...oI,
                        getGlobalClaimValidators: ({ claimValidatorsAddedByOtherRecipes }) => [
                            ...claimValidatorsAddedByOtherRecipes,
                            {
                                ...AllowedDomainsClaim.validators.hasAccessToCurrentDomain(),
                                onFailureRedirection: async () => {
                                    let claimValue = await Session.getClaimValue({
                                        claim: AllowedDomainsClaim,
                                    });

                                    return "https://" + claimValue![0];
                                },
                            },
                        ],
                    }),
                },
            })
        ]
    });
}