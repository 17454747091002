import {useIntl} from "react-intl";
import {Link} from "react-router-dom";
import {Card} from "react-bootstrap";
import {Helmet} from "react-helmet-async";

import {PageTitle} from "../../../../_metronic/layout/core";
import {TableHeader, PaginatedTable} from "components";
import TableUtilities from "components/dist/tables/table-utilities";


const Page = () => {
    const headers: TableHeader[] = [
        {   key: "payoutRef",
            label: "Reference",
            format: (cell) => {
                return (
                    <Link className="text-grey" to={`/payouts/paid/${cell.row.id}`}>
                        {cell.row.payoutRef}
                    </Link>
                )
            }
        },
        {
            key: "fullName",
            label: "Consigner",
        },
        {key: "recipientEmail", label: "Email"},
        {
            key: "createdAt",
            label: "Created At",
            format: (cell) => TableUtilities.formatDate(cell.item)
        },
        {key: "quantity", label: "Items"},
        {
            key: "payoutAmount",
            label: "Payout Amount",
            format: (cell) => TableUtilities.formatCurrencyCents(cell.item),
            cellClassName: "text-white"
        },
        {
            key: "profitSubtotal",
            label: "Store Profit",
            format: (cell) => TableUtilities.formatCurrencyCents(cell.item)
        },
    ]

    return (
        <>
            <Card>
                <Card.Body className="pt-5">
                    <PaginatedTable
                        key={null}
                        headers={headers}
                        dataProperty="payouts"
                        method="get"
                        dataUrl="/api/v1/payout/paid/all"
                        className={""}
                        paginate={true}
                        filterLocally={true}
                    />
                </Card.Body>
            </Card>
        </>
    )
}


const PayoutPaidWrapper = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.PAYOUT_PAID'})}</PageTitle>
            <Helmet>
                <title>{intl.formatMessage({id: 'MENU.PAYOUT_PAID'})} - Bind</title>
            </Helmet>
            <Page/>
        </>
    )
}

export default PayoutPaidWrapper;