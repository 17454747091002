import axios from "axios";


const PayoutService = {
    fetchPayouts: async (consignorId: string) : Promise<PayoutRoot> => {
        const response = await axios.get(`/api/v1/payout/view/consignor/${consignorId}`)
        return response.data
    },
    fetchBatchPayout: async (batchId: string) : Promise<PayoutBatchRoot> => {
        const response = await axios.get(`/api/v1/payout/view/batch/${batchId}`)
        return response.data
    }
}


export interface PayoutRoot {
    details: PayoutDetails;
    payouts: Payout[];
}

export interface PayoutBatchRoot {
    details: PayoutBatchDetails;
    batchPayout: PayoutBatch;
}

interface PayoutBatchDetails {
    userId: string;
    fullName: string;
    email: string;
    checkCount: number;
    payoutCount: number;
    payoutAmountSubtotal: number;
    profitSubtotal: number;
}


export interface PayoutBatch {
    id: string
    createdAt: string
    payoutRef: string
    recipientEmail: string
    quantity: number
    payout: Payout[]
}

interface PayoutDetails {
    creatorId: string;
    fullName: string;
    email: string;
    transferSent: boolean;
    payoutCount: number;
    payoutAmountSubtotal: number;
    profitSubtotal: number
}

interface Payout {
    id: string
    createdAt: string
    stripeTransferId: string
    transferSent: boolean
    orderItem: OrderItem
}

interface OrderSummary {
    id: string;
    createdAt: Date;
    status: string;
    source: string;
    orderId: number;
    orderNumber: string;
    subtotal: number;
    shippingTotal: number;
    taxTotal: number;
    priceTotal: number;
    quantity: number;
    profitTotal: number;
    currency: string;
    shopifyUrl: string;
    organizationId: string;
}

interface OrderItem {
    id: string;
    createdAt: string;
    profitTotal: number;
    subtotal: number;
    payoutAmount: number;
    status: string;
    orderSummary: OrderSummary
    item: ProductItem;
}

interface ProductItem {
    id: string;
    createdAt: string;
    storeOwned: boolean;
    size: string;
    price: number;
    shape: string;
    status: string;
    product: ProductDetails;
    user: User | null;
}

interface ProductDetails {
    id: string;
    createdAt: string;
    title: string;
    primaryTitle: string;
    secondaryTitle: string;
    brand: string;
    sku: string;
    colorway: string;
    gender: string;
    releaseDate: string;
    imageUrl: string;
}

interface User {
    id: string;
    firstName: string;
    lastName: string;
    userType: string;
    fullName: string;
}


export default PayoutService