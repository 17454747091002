/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'

type Props = {
  className: string
}

const TablesWidget11: React.FC<Props> = ({className}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Recent Orders</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-325px rounded-start'>Product</th>
                <th className='min-w-150px'>Date</th>
                <th className='min-w-125px'>Size</th>
                <th className='min-w-200px'>Price</th>
                <th className='min-w-150px'>Status</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              <tr>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-50px me-5'>
                      <img
                        src='https://i.imgur.com/h5S5BVm.png'
                        className=''
                        alt=''
                      />
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-semibold text-hover-primary mb-1 fs-6'>
                        Product Title
                      </a>
                    </div>
                  </div>
                </td>
                <td>
                  <a className='text-dark fw-semibold d-block mb-1 fs-6'>
                    March 20, 2023
                  </a>
                </td>
                <td>
                  <a className='text-dark fw-semibold d-block mb-1 fs-6'>
                    10
                  </a>
                </td>
                <td>
                  <a className='text-dark fw-semibold d-block mb-1 fs-6'>
                    $100
                  </a>
                </td>
                <td>
                  <span className='badge badge-light-warning fs-7 fw-semibold'>Sold</span>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-50px me-5'>
                      <img
                        src='https://i.imgur.com/h5S5BVm.png'
                        className=''
                        alt=''
                      />
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-semibold text-hover-primary mb-1 fs-6'>
                        Product Title
                      </a>
                    </div>
                  </div>
                </td>
                <td>
                  <a className='text-dark fw-semibold d-block mb-1 fs-6'>
                    March 20, 2023
                  </a>
                </td>
                <td>
                  <a className='text-dark fw-semibold d-block mb-1 fs-6'>
                    10
                  </a>
                </td>
                <td>
                  <a className='text-dark fw-semibold d-block mb-1 fs-6'>
                    $100
                  </a>
                </td>
                <td>
                  <span className='badge badge-light-warning fs-7 fw-semibold'>Sold</span>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-50px me-5'>
                      <img
                        src='https://i.imgur.com/h5S5BVm.png'
                        className=''
                        alt=''
                      />
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-semibold text-hover-primary mb-1 fs-6'>
                        Product Title
                      </a>
                    </div>
                  </div>
                </td>
                <td>
                  <a className='text-dark fw-semibold d-block mb-1 fs-6'>
                    March 20, 2023
                  </a>
                </td>
                <td>
                  <a className='text-dark fw-semibold d-block mb-1 fs-6'>
                    10
                  </a>
                </td>
                <td>
                  <a className='text-dark fw-semibold d-block mb-1 fs-6'>
                    $100
                  </a>
                </td>
                <td>
                  <span className='badge badge-light-success fs-7 fw-semibold'>Paid Out</span>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-50px me-5'>
                      <img
                        src='https://i.imgur.com/h5S5BVm.png'
                        className=''
                        alt=''
                      />
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-semibold text-hover-primary mb-1 fs-6'>
                        Product Title
                      </a>
                    </div>
                  </div>
                </td>
                <td>
                  <a className='text-dark fw-semibold d-block mb-1 fs-6'>
                    March 20, 2023
                  </a>
                </td>
                <td>
                  <a className='text-dark fw-semibold d-block mb-1 fs-6'>
                    10
                  </a>
                </td>
                <td>
                  <a className='text-dark fw-semibold d-block mb-1 fs-6'>
                    $100
                  </a>
                </td>
                <td>
                  <span className='badge badge-light-success fs-7 fw-semibold'>Paid Out</span>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-50px me-5'>
                      <img
                        src='https://i.imgur.com/h5S5BVm.png'
                        className=''
                        alt=''
                      />
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-semibold text-hover-primary mb-1 fs-6'>
                        Product Title
                      </a>
                    </div>
                  </div>
                </td>
                <td>
                  <a className='text-dark fw-semibold d-block mb-1 fs-6'>
                    March 20, 2023
                  </a>
                </td>
                <td>
                  <a className='text-dark fw-semibold d-block mb-1 fs-6'>
                    10
                  </a>
                </td>
                <td>
                  <a className='text-dark fw-semibold d-block mb-1 fs-6'>
                    $100
                  </a>
                </td>
                <td>
                  <span className='badge badge-light-success fs-7 fw-semibold'>Paid Out</span>
                </td>
              </tr>
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {TablesWidget11}
