import {createRoot} from 'react-dom/client'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
// Apps
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
import './_metronic/assets/fonticon/fonticon.css'
import './_metronic/assets/keenicons/duotone/style.css'
import './_metronic/assets/keenicons/outline/style.css'
import './_metronic/assets/keenicons/solid/style.css'
import "react-datepicker/dist/react-datepicker.css";
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import 'components/dist/css/style.css'
import 'components/dist/css/plugins.css'
import 'components/dist/css/style.react.css'
import {AppRoutes} from './app/routing/AppRoutes'
import {setupAxios} from "./app/modules/auth/axios-auth";
import {initSupertokens} from "./app/modules/auth/supertokens";
import {Helmet, HelmetProvider} from "react-helmet-async";

initSupertokens()
setupAxios(axios)

Chart.register(...registerables)

const queryClient = new QueryClient()
const container = document.getElementById('root')
if (container) {
    createRoot(container).render(
        <QueryClientProvider client={queryClient}>
            <HelmetProvider>
                <MetronicI18nProvider>
                    <AppRoutes/>
                </MetronicI18nProvider>
            </HelmetProvider>
        </QueryClientProvider>
    )
}
