import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import OrdersPage from "../pages/orders/orders"
import InventoryPage from '../pages/inventory/discover'
import InboundInventoryPage from "../pages/inventory/inbound";
import PendingInventoryWrapper from "../pages/inventory/pending";
import ViewInventoryItemWrapper from "../pages/inventory/view";
import CatalogPage from '../pages/catalog/catalog'
import ItemLabelsPage from '../pages/labels/item'
import ProductLabelsPage from '../pages/labels/product'
import ConsignorsPage from '../pages/consignors/consignors'
import EmployeesPage from '../pages/employees/employees'
import EmployeePage from "../pages/employees/view";
import SettingsPage from '../pages/settings'
import ProductPageWrapper from "../pages/catalog/product";
import CreateProductWrapper from "../pages/catalog/create";
import ViewOrderPage from "../pages/orders/view";
import ViewPayoutPage from "../pages/payouts/pages/ViewPayoutAwaiting";
import ViewPayoutsPageWrapper from '../pages/payouts/pages/ViewPaid'
import ConsignorView from "../pages/consignors/view";
import LocationsPage from "../pages/settings/locations";
import TiersPage from "../pages/settings/tiers";
import GeneralPage from "../pages/settings/general";
import CategoriesPage from "../pages/settings/categories";
import OnboardWrapper from "../pages/catalog/onboard";
import PayoutAwaiting from "../pages/payouts/pages/PayoutAwaiting";
import PayoutPaid from "../pages/payouts/pages/PayoutPaid";
import {SessionAuth} from 'supertokens-auth-react/recipe/session';
import AdminRoute from './AdminRoute';

const PrivateRoutes = () => {
    const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
    const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
    const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
    const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
    const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
    const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))


    return (
        <Routes>
            <Route element={<MasterLayout/>}>
                {/* Redirect to Dashboard after success login/registartion */}
                <Route path='auth/*' element={<Navigate to='/dashboard'/>}/>
                {/* Pages */}
                <Route path='dashboard' element={<SessionAuth> <DashboardWrapper/></SessionAuth>}/>
                <Route path='orders' element={<SessionAuth> <OrdersPage/> </SessionAuth>}/>
                <Route path='orders/:id' element={<SessionAuth> <ViewOrderPage/> </SessionAuth>}/>
                <Route path='payouts/:id' element={<SessionAuth> <ViewPayoutPage/> </SessionAuth>}/>
                <Route path='payouts/paid/:id' element={<SessionAuth> <ViewPayoutsPageWrapper/> </SessionAuth>}/>
                <Route path='payouts' element={
                    <SessionAuth>
                        <AdminRoute>
                            <OrdersPage/>
                        </AdminRoute>
                    </SessionAuth>
                }/>
                <Route path='payouts/awaiting' element={
                    <SessionAuth>
                    <AdminRoute>
                        <PayoutAwaiting/>
                    </AdminRoute>
                    </SessionAuth>
                }/>
                <Route path='payouts/paid' element={
                    <SessionAuth>
                    <AdminRoute>
                        <PayoutPaid/>
                    </AdminRoute>
                    </SessionAuth>
                }/>
                <Route path='inventory' element={<SessionAuth> <InventoryPage/></SessionAuth>}/>
                <Route path='inventory/discover' element={<SessionAuth> <InventoryPage/></SessionAuth> }/>
                <Route path='inventory/inbound' element={<SessionAuth> <InboundInventoryPage/></SessionAuth> }/>
                <Route path='inventory/pending' element={<SessionAuth> <PendingInventoryWrapper/></SessionAuth> }/>
                <Route path='inventory/items/:id' element={<SessionAuth> <ViewInventoryItemWrapper/></SessionAuth> }/>
                <Route path='catalog' element={<SessionAuth> <CatalogPage/></SessionAuth> }/>
                <Route path='catalog/:id' element={<SessionAuth> <ProductPageWrapper/></SessionAuth> }/>
                <Route path='catalog/create' element={<SessionAuth> <CreateProductWrapper/></SessionAuth> }/>
                <Route path='catalog/:id/onboard' element={<SessionAuth> <OnboardWrapper/></SessionAuth> }/>
                <Route path='labels/item' element={<SessionAuth> <ItemLabelsPage/></SessionAuth> }/>
                <Route path='labels/product' element={<SessionAuth> <ProductLabelsPage/></SessionAuth> }/>
                <Route path='consignors' element={<SessionAuth> <ConsignorsPage/></SessionAuth> }/>
                <Route path='consignors/:id' element={<SessionAuth> <ConsignorView/></SessionAuth> }/>
                <Route path='employees' element={<SessionAuth> <EmployeesPage/></SessionAuth> }/>
                <Route path='employees/:id' element={<SessionAuth> <EmployeePage/></SessionAuth> }/>
                <Route path='settings' element={<SessionAuth> <SettingsPage/></SessionAuth> }/>
                <Route path='settings/locations' element={<SessionAuth> <LocationsPage/></SessionAuth> }/>
                <Route path='settings/tiers' element={<SessionAuth> <TiersPage/></SessionAuth> }/>
                <Route path='settings/categories' element={<SessionAuth> <CategoriesPage/></SessionAuth> }/>
                <Route path='settings/general' element={<SessionAuth> <GeneralPage/></SessionAuth>}/>
                {/* Page Not Found */}
                <Route path='*' element={<Navigate to='/dashboard'/>}/>
            </Route>
        </Routes>
    )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
    const baseColor = getCSSVariableValue('--bs-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress/>}>{children}</Suspense>
}

export {PrivateRoutes}
