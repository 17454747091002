import { useIntl } from "react-intl"
import { PageTitle } from "../../../../_metronic/layout/core"
import {Card, Col, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import PayoutService, {PayoutRoot} from "../../../modules/dashboard/payouts/payout.service";
import TableUtilities from "components/dist/tables/table-utilities";
import {Link, useParams} from "react-router-dom";
import {Helmet} from "react-helmet-async";


const ViewPayoutPageContent = () => {
    const [payoutsRoot, setPayoutsRoot] = useState<PayoutRoot | null>(null);

    const {id} = useParams();

    useEffect(() => {
        (async () => {
            const response = await PayoutService.fetchPayouts(id!)
            setPayoutsRoot(response)
        })()
    }, [])

    return (
        <Row>
            <Col md={8}>
                <Card className="pt-4" title="">
                    <Card.Body>
                        <div>
                            <h2 className='d-inline-block align-middle'>
                                Pending Payout
                                {payoutsRoot && payoutsRoot.details.fullName && (
                                    <Link to={`/consignors/${payoutsRoot.details.creatorId}`} className="ms-3">
                                        <span className="badge badge-secondary text-capitalize min-w-75px justify-content-center align-middle">
                                            {payoutsRoot.details.fullName}
                                        </span>
                                    </Link>
                                )}
                            </h2>
                        </div>

                        <div className="mt-4">
                            {payoutsRoot?.payouts?.map(payout => (
                                <div className="d-flex mt-7">
                                    <div className="me-5 bg-white p-2 rounded">
                                        <Link to={`/inventory/items/${payout.orderItem.item.id}`}>
                                            <div
                                                style={{
                                                    backgroundImage: `url("${payout.orderItem.item.product.imageUrl}")`,
                                                    backgroundSize: 'contain',
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundPosition: 'center center',
                                                    height: '90px',
                                                    width: '90px'
                                                }}>
                                            </div>
                                        </Link>
                                    </div>

                                    <div className="d-flex justify-content-center flex-column gap-1 w-100">
                                        <span className='h1 text-dark fw-bolder mb-1 fs-5'>
                                            <Link className='text-white' to={`/inventory/items/${payout.orderItem.item.id}`}>
                                                {payout.orderItem.item.product.title}
                                            </Link>

                                            <span className={"float-end"}>
                                                {TableUtilities.formatCurrencyCents(payout.orderItem.payoutAmount)}
                                            </span>
                                        </span>
                                        <span className="text-muted text-capitalize">
                                            {payout.orderItem.item.size} - {payout.orderItem.item.shape}

                                            <span className='text-muted text-capitalize float-end'>
                                                Store Profit: {TableUtilities.formatCurrencyCents(payout.orderItem.profitTotal)}
                                            </span>
                                        </span>
                                        <span className='text-muted'>{payout.orderItem.item.product.sku}</span>
                                        {payout.orderItem?.orderSummary && (
                                            <span>
                                                <a href={payout.orderItem.orderSummary.shopifyUrl} target="_blank">
                                                    <span className='badge badge-secondary min-w-75px justify-content-center'>
                                                        Order #{payout.orderItem.orderSummary.orderNumber}
                                                    </span>
                                                </a>
                                            </span>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>

                    </Card.Body>
                </Card>
            </Col>
            <Col md={4}>
                <Card className="pt-4" title="">
                    <Card.Body>
                        <div className="pb-7">
                            <h2>Payout Details</h2>
                        </div>

                        <div className="d-flex flex-column gap-1">
                        <div className="d-flex justify-content-between w-100 fs-6">
                            <p className="text-muted">Item Count</p>
                            <p className="text-white">{payoutsRoot?.details.payoutCount}</p>
                        </div>
                        <div className="d-flex justify-content-between w-100 fs-6">
                            <p className="text-muted">Store Profit Amount</p>
                            <p className="text-white">
                                {TableUtilities.formatCurrencyCents(payoutsRoot?.details.profitSubtotal ?? 0)}
                            </p>
                        </div>
                        <div className="d-flex justify-content-between w-100 fs-6">
                            <p className="text-muted">Payout Amount</p>
                            <p className="text-white">
                                {TableUtilities.formatCurrencyCents(payoutsRoot?.details.payoutAmountSubtotal ?? 0)}
                            </p>
                        </div>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}


const ViewPayoutPage = () => {
    const intl = useIntl()

    return (
        <>
            <PageTitle breadcrumbs={[{
                title: "Awaiting Payouts",
                path: "/payouts/awaiting",
                isActive: false
            }]}>{intl.formatMessage({id: "MENU.VIEW_AWAITING_PAYOUT"})}</PageTitle>
            <Helmet>
                <title>{intl.formatMessage({id: "MENU.VIEW_AWAITING_PAYOUT"})} - Bind</title>
            </Helmet>
            <ViewPayoutPageContent />
        </>
    )
}

export default ViewPayoutPage