import React, {ReactNode} from "react";

type Props = {
    className: string,
    title: string,
    children: ReactNode
}

const Card: React.FC<Props> = ({title, className, children}) => {
    return (
        <div className={`card card-xl mb-5 mb-xl-8 ${className}`}>
            {/* begin::Header */}
            {title.length > 0 &&
                <div className='card-header border-0'>
                    <h3 className='card-title fw-bold text-dark'>{title}</h3>
                </div>
            }
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body pt-0'>
                {children}
            </div>
        </div>
    )
}

export default Card;