import Card from "../../modules/dashboard/dashboard/components/Card";
import React from "react";
import {useIntl} from "react-intl";
import {PageTitle} from "../../../_metronic/layout/core";
import InventoryTable from "../../modules/dashboard/inventory/components/InventoryTable";
import {InventoryItemStatus} from "../../modules/dashboard/inventory/inventory.service";
import {Helmet} from "react-helmet-async";

const DiscoverInventoryPage = () => {
    return (
        <>
            <Card className={'p-5'} title=''>
                <InventoryTable />
            </Card>
        </>
    )
}

const DiscoverInventoryWrapper = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DISCOVER'})}</PageTitle>
            <Helmet>
                <title>{intl.formatMessage({id: 'MENU.DISCOVER'})} - Bind</title>
            </Helmet>
            <DiscoverInventoryPage/>
        </>
    )
}

export default DiscoverInventoryWrapper