/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {FC, useEffect} from 'react'
import {Routes, Route, BrowserRouter, Navigate, useLocation} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {App} from '../App'

import {SuperTokensWrapper} from "supertokens-auth-react";
import {getSuperTokensRoutesForReactRouterDom} from "supertokens-auth-react/ui";
import * as reactRouterDom from "react-router-dom";
import {EmailPasswordPreBuiltUI} from "supertokens-auth-react/recipe/emailpassword/prebuiltui";
import {EmailVerificationPreBuiltUI} from "supertokens-auth-react/recipe/emailverification/prebuiltui";
import {AuthWrapper} from "../modules/auth/AuthWrapper";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const useUpdateTitleOnRouteChange = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.pathname.startsWith('/auth')) {
            let title = 'Login'; // Default title

            if (location.pathname.includes('reset-password'))
                title = 'Reset Password'

            document.title = `${title} - Bind`;
        }
    }, [location]);
};

const PageTitleUpdater = () => {
    useUpdateTitleOnRouteChange();
    return null; // This component does not render anything
};

const AppRoutes: FC = () => {
    return (
        <SuperTokensWrapper>
            <BrowserRouter basename={PUBLIC_URL}>
                <PageTitleUpdater/>

                <Routes>
                    {/*This renders the login UI on the /auth route*/}
                    {getSuperTokensRoutesForReactRouterDom(reactRouterDom, [EmailPasswordPreBuiltUI, EmailVerificationPreBuiltUI])}
                    {/*Your app routes*/}

                    <Route path="/" element={<AuthWrapper/>}>
                        <Route element={<App/>}>
                            <Route path='/*' element={<PrivateRoutes/>}/>
                            <Route path='error/*' element={<ErrorsPage/>}/>
                        </Route>
                    </Route>
                </Routes>
            </BrowserRouter>
        </SuperTokensWrapper>
    )
}

export {AppRoutes}
