import React from "react";
import Session, {SessionAuth} from 'supertokens-auth-react/recipe/session';
import {AccessDeniedScreen} from 'supertokens-auth-react/recipe/session/prebuiltui';
import {UserRoleClaim, /*PermissionClaim*/} from 'supertokens-auth-react/recipe/userroles';
import {Navigate} from "react-router-dom";

const AdminRoute = (props: React.PropsWithChildren<any>) => {

    let claimValue = Session.useClaimValue(UserRoleClaim)

    if (claimValue.loading || !claimValue.doesSessionExist) {
        return null;
    }

    let roles = claimValue.value;

    if(Array.isArray(roles) && !roles.includes("admin"))
        return <Navigate to={'/dashboard'}/>

    return props.children;
}

export default AdminRoute