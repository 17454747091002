import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import React, {ChangeEvent, useEffect, useState} from "react";
import {toast, Toaster} from "react-hot-toast";
import axios from "axios";
import {Tier} from "../../consignors/consignors.service";

type Props = {
    tier?: Tier
    show: boolean,
    onClose: () => void,
    onTierChange: () => void,
    onEmployeeCreated?: () => void
}


const CreateTierModal: React.FC<Props> = ({tier, show, onClose, onEmployeeCreated, onTierChange}) => {
    const [tierName, setTierName] = useState('');
    const [fee, setFee] = useState('');
    const [minimumFee, setMinimumFee] = useState('');
    // const [defaultTier, setDefaultTier] = useState(false)

    useEffect(() => {
        setTierName(tier?.name ?? '')
        setFee(tier?.percent.toString() ?? '')
        setMinimumFee(((tier ? tier.minimumFee : 0) / 100).toString())
        // setDefaultTier(tier?.default ?? false)
    }, [tier]);

    const handleClose = () => onClose();

    const createTier = async (e: React.FormEvent) => {
        e.preventDefault();

        toast.loading('Please wait...', {id: 'createTier'})

        try {
            const response = await axios({
                method: !!tier ? 'patch' : 'post',
                url: !!tier ? '/api/v1/tier/update' : '/api/v1/tier/create',
                data: {
                    ...tier && {tier_id: tier.id},
                    name: tierName,
                    minimum_fee: minimumFee,
                    percent: fee,
                    // default: defaultTier
                }
            })

            onTierChange()
            handleClose()

            toast.success(response.data.message, {
                id: 'createTier',
                duration: 8000
            })
        } catch (e: any) {
            toast.error(e.response.data.details.message, {
                id: 'createTier',
                duration: 5000
            })
        }
    };

    const modalTitle = tier ? 'Update Consignor Tier' : 'Create Consignor Tier';

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton className={'border-0 pb-0'}>
                </Modal.Header>
                <Modal.Body className='pt-10'>
                    <h2 className='text-center mb-10 fw-bolder fs-1'>{modalTitle}</h2>

                    <Form className='mt-10' onSubmit={createTier}>
                        <Form.Group className="mb-2 p-4" controlId="tier">
                            <Form.Label className='fw-bold'>Tier</Form.Label>
                            <Form.Control
                                value={tierName}
                                onChange={(e) => setTierName(e.target.value)}
                                className='form-control-solid'
                                placeholder='Level 4'
                                required
                            />
                        </Form.Group>
                        <Row className="mb-2 p-4">
                            <Form.Group as={Col} controlId="city">
                                <Form.Label className='fw-bold'>Sale Fee</Form.Label>
                                <Form.Control
                                    value={fee}
                                    onChange={(e) => setFee(e.target.value)}
                                    className='form-control-solid'
                                    placeholder='8'
                                    required
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="state">
                                <Form.Label className='fw-bold'>Minimum Fee</Form.Label>
                                <Form.Control
                                    value={minimumFee}
                                    onChange={(e) => setMinimumFee(e.target.value)}
                                    className='form-control-solid'
                                    placeholder='20'
                                    required
                                />
                            </Form.Group>
                        </Row>

                        {/*<Form.Group className="mb-2 p-4" controlId="check">*/}
                        {/*    <div className='d-flex flex-row align-items-center justify-content-between'>*/}
                        {/*        <div className='pe-15'>*/}
                        {/*            <Form.Label className='fw-bold'>Tier</Form.Label>*/}
                        {/*            <p className='small text-muted'>When a tier is assigned as the default tier, new*/}
                        {/*                consignors will*/}
                        {/*                be given the default tier automatically.*/}
                        {/*            </p>*/}
                        {/*        </div>*/}

                        {/*        <Form.Check // prettier-ignore*/}
                        {/*            checked={defaultTier}*/}
                        {/*            onChange={(e) => setDefaultTier(e.target.checked)}*/}
                        {/*            type={"checkbox"}*/}
                        {/*            id={`check`}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</Form.Group>*/}

                        <Form.Group className="px-4">
                            <Button variant="primary" className='w-100 max-w-100 mt-10 mb-10' type='submit'>
                                Submit
                            </Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CreateTierModal;