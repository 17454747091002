/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import Session from "supertokens-auth-react/recipe/session";
import {UserRoleClaim} from "supertokens-auth-react/recipe/userroles";
import {Navigate} from "react-router-dom";
import React from "react";

export function AsideMenuMain() {
    const intl = useIntl()


    let claimValue = Session.useClaimValue(UserRoleClaim)

    if (claimValue.loading || !claimValue.doesSessionExist) {
        return null;
    }

    let roles = claimValue.value;

    const isAdmin = Array.isArray(roles) && roles.includes("admin");

    return (
        <>
            <AsideMenuItem to='/dashboard' icon='element-11' title={intl.formatMessage({id: 'MENU.DASHBOARD'})}/>
            <AsideMenuItem to='/orders' title='Orders' icon='shop'/>
            {isAdmin &&
                <AsideMenuItemWithSub to='/payouts' title='Payouts' icon='dollar'>
                    <AsideMenuItem to='/payouts/awaiting' title='Awaiting Payment' hasBullet={true}/>
                    <AsideMenuItem to='/payouts/paid' title='Paid' hasBullet={true}/>
                </AsideMenuItemWithSub>
            }
            <AsideMenuItemWithSub to='/inventory' title='Inventory' icon='price-tag'>
                <AsideMenuItem to='/inventory/discover' title='Discover' hasBullet={true}/>
                <AsideMenuItem to='/inventory/inbound' title='Inbound' hasBullet={true}/>
                <AsideMenuItem to='/inventory/pending' title='Pending Withdrawals' hasBullet={true}/>
            </AsideMenuItemWithSub>
            <AsideMenuItem to='/catalog' title='Catalog' icon='book'/>
            <AsideMenuItemWithSub to='/labels' title='Labels' icon='scan-barcode'>
                <AsideMenuItem to='/labels/item' title='Item' hasBullet={true}/>
                <AsideMenuItem to='/labels/product' title='Product' hasBullet={true}/>
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub to='/crafted/pages' title='Users' icon='profile-circle'>
                <AsideMenuItem to='/consignors' title='Consigners' hasBullet={true}/>
                {isAdmin &&
                    <AsideMenuItem to='/employees' title='Employees' hasBullet={true}/>
                }
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub to='/settings' title='Settings' icon='setting-2'>
                <AsideMenuItem to='/settings/general' title='General' hasBullet={true}/>
                <AsideMenuItem to='/settings/tiers' title='Tiers' hasBullet={true}/>
                <AsideMenuItem to='/settings/locations' title='Locations' hasBullet={true}/>
                <AsideMenuItem to='/settings/categories' title='Categories' hasBullet={true}/>
            </AsideMenuItemWithSub>
        </>
    )
}
