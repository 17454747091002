export enum EventType {
    ITEM_WITHDRAWN = "ITEM_WITHDRAWN",
    CATEGORY_UPDATED = "CATEGORY_UPDATED",
    ITEM_WITHDRAWAL_CREATED = "ITEM_WITHDRAWAL_CREATED",
    ITEM_ACCEPTED = "ITEM_ACCEPTED",
    LOCATION_UPDATED = "LOCATION_UPDATED",
    TIER_UPDATED = "TIER_UPDATED",
    PRODUCT_CREATED = "PRODUCT_CREATED",
    ITEM_PRICE_UPDATE = "ITEM_PRICE_UPDATE",
    ITEM_ONBOARDED = "ITEM_ONBOARDED",
    TIER_CREATED = "TIER_CREATED",
}

interface EventBase {
    id: string;
    createdAt: string;
    eventType: EventType;
    title: string;
    description: string;
}

interface Item {
    id: string;
    product: Product;
}

interface Product {
    id: string;
    title: string;
}

interface Category {
    id: string;
    name: string;
}

interface Location {
    id: string;
    name: string;
}

interface Tier {
    id: string;
    name: string;
}

// Event interfaces (Extending from EventBase)

export interface ItemEvent extends EventBase {
    item: Item;
}

export interface CategoryEvent extends EventBase {
    category: Category;
}

export interface LocationEvent extends EventBase {
    location: Location;
}

export interface TierEvent extends EventBase {
    tier: Tier;
}

export interface ProductEvent extends EventBase {
    product: Product;
}

export type EmployeeEvent = ItemEvent | CategoryEvent | LocationEvent | TierEvent | ProductEvent;
