import React, {useEffect, useState} from "react";

import {InventoryItem, InventoryItemStatus} from "../inventory.service";
import InventoryFilterDropdown from "./InventoryFilterDropdown";
import {PaginatedTable, TableHeader} from "components";
import TableUtilities from "components/dist/tables/table-utilities";
import {Link} from "react-router-dom";

interface Props {
    tableKey?: number,
    statusFilter?: InventoryItemStatus,
    selectable?: boolean
    onSelected?: (selectedItems: InventoryItem[]) => void
    actions?: JSX.Element,
}

const InventoryTable: React.FC<Props> = ({statusFilter, onSelected, selectable = false, actions, tableKey}) => {
    const [status, setStatus] = useState<InventoryItemStatus>(InventoryItemStatus.All);
    const [selectedItems, setSelectedItems] = useState<InventoryItem[]>([]);

    const [tableFilters, setTableFilters] = useState<Record<string, string>>({
        status: statusFilter ?? status
    });

    const onFilterChange = (filters: Record<string, string>) => {
        setTableFilters(filters);
    }

    const image = (item: InventoryItem) => {
        return TableUtilities.tableImage(item.product.imageUrl, item.id)
    }

    const titleAndSize = (row: InventoryItem) => {
        return <div>
            <Link className={'text-white d-block'} to={`/inventory/items/${row.id}`}>{row.product.title}</Link>
            <span className={'mt-3'}>{row.size} / {row.shape}</span>
        </div>
    }

    const formatCondition = (condition: string) => {
        return (
            <>
                <span className='text-capitalize'>
                    {condition}
                </span>
            </>
        )
    }

    const formatOwnership = (item: InventoryItem) => {
        if (item.storeOwned) {
            return 'Store Owned'
        } else {
            return <Link to={`/consignors/${item.user.id}`} className='text-gray-600 fw-bold'>{item.user.fullName}</Link>
        }
    }

    const headers: TableHeader[] = [
        {key: 'image', label: '', format: (cell) => image(cell.row)},
        {key: 'product.title', label: 'Item Title', format: (cell) => titleAndSize(cell.row)},
        {key: 'itemRef', label: 'Reference'},
        {key: 'price', label: 'Price', format: (cell) => TableUtilities.formatCurrencyCents(cell.item)},
        {key: 'user.fullName', label: 'Ownership', format: (cell) => formatOwnership(cell.row)},
        {key: 'location.name', label: 'Location'},
        {key: 'createdAt', label: 'Created At', format: (cell) => TableUtilities.formatDate(cell.item)},
        {key: 'status', label: 'Status', format: (cell) => TableUtilities.statusBadge(cell.item)}
    ]

    const onItemsSelected = (items: any[]) => {
        if (!!onSelected) {
            onSelected(items as InventoryItem[])
        }

        setSelectedItems(items);
    }

    useEffect(() => {
        // Update selected items when the tableKey changes
        setSelectedItems([]);
    }, [tableKey]);

    const tableActions = () => (
        <>
            <div className='d-flex gap-10'>
                {!!actions && selectable && selectedItems.length > 0 && actions}

                <InventoryFilterDropdown
                    displayStatus={!statusFilter}
                    onFilterChange={onFilterChange}
                    defaultStatus={statusFilter || InventoryItemStatus.All}
                />
            </div>
        </>
    )

    return (
        <>
            <PaginatedTable
                key={tableKey}
                className={'mt-5'}
                headers={headers}
                method={'post'}
                dataUrl={'/api/v1/item/management/search'}
                paginate={true}
                actions={tableActions()}
                filters={tableFilters}
                selectable={selectable}
                selectedItems={selectedItems}
                onSelected={onItemsSelected}
            />
        </>
    )
}

export default InventoryTable;