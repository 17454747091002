import Card from "../../modules/dashboard/dashboard/components/Card";
import React, {useState} from "react";
import {toast, Toaster} from "react-hot-toast";

import InventoryService, {
    InventoryItem,
    InventoryItemStatus
} from "../../modules/dashboard/inventory/inventory.service";
import {useIntl} from "react-intl";
import {PageTitle} from "../../../_metronic/layout/core";
import InventoryTable from "../../modules/dashboard/inventory/components/InventoryTable";
import {Button} from "react-bootstrap";
import {ConsignorFilterDropdown} from "../payouts/components/ConsignorFilterModal";
import DeclineItemsModal from "./components/DeclineItemsModal";
import {Helmet} from "react-helmet-async";

const InboundInventoryPage = () => {
    const [selectedItems, setSelectedItems] = useState<InventoryItem[]>([]);
    const [childKey, setChildKey] = useState<number>(512);
    const [showDeclineModal, setShowDeclineModal] = useState<boolean>(false);

    const onAcceptItemsClick = async () => {
        if (selectedItems.length === 0) return;

        toast.loading('Please wait...', {id: 'acceptItems'})

        try {
            const response = await InventoryService.acceptItems(selectedItems.map(s => s.id))

            // Update the child key to force a re-render/re-fetch of the table data.
            setChildKey(childKey * 89);

            toast.success(response.data.message, {
                id: 'acceptItems',
                duration: 8000
            })
        } catch (e: any) {
            toast.error(e.response.data.details.message, {
                id: 'acceptItems',
                duration: 5000
            })
        }
    }

    const onDeclineItemsClick = () => {
        setShowDeclineModal(true);
    }


    const onItemsSelected = (items: InventoryItem[]) => {
        setSelectedItems(items)
    }

    const actions = (
        <>
            <div className='d-flex align-items-center gap-3'>
                {selectedItems.length > 0 && (
                    <>
                        <span className='fw-bold'>
                            {selectedItems.length} Items Selected
                        </span>
                    </>
                )}
                <Button variant={"secondary"} className='ms-3' onClick={onDeclineItemsClick}>
                    Decline Items
                </Button>

                <Button variant={"primary"} className='ms-2' onClick={onAcceptItemsClick}>
                    Accept Items
                </Button>
            </div>
        </>
    )

    return (
        <>
            <Card className={'p-5'} title=''>
                <InventoryTable tableKey={childKey} selectable={true} actions={actions}
                                statusFilter={InventoryItemStatus.Awaiting} onSelected={onItemsSelected}/>
            </Card>

            <DeclineItemsModal showModal={showDeclineModal} onClose={() => setShowDeclineModal(false)} selectedItems={selectedItems} />
        </>
    )
}

const InboundInventoryWrapper = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.INBOUND'})}</PageTitle>
            <Helmet>
                <title>{intl.formatMessage({id: 'MENU.INBOUND'})} - Bind</title>
            </Helmet>
            <InboundInventoryPage/>
        </>
    )
}

export default InboundInventoryWrapper