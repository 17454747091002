import {useIntl} from 'react-intl'
import {PageTitle} from "../../../_metronic/layout/core";
import {Card, Col, Row} from "react-bootstrap";
import TableUtilities from "components/dist/tables/table-utilities";
import React, {useEffect, useState} from "react";
import OrdersService, {OrderDetails} from "../../modules/dashboard/orders/orders.service";
import {Link, useParams} from "react-router-dom";
import orders from "./orders";
import {Helmet} from "react-helmet-async";

const ViewOrderPageContent = () => {
    const [orderDetails, setOrderDetails] = useState<OrderDetails | null>(null);

    const {id} = useParams();

    useEffect(() => {
        (async () => {
            setOrderDetails(await OrdersService.fetchOrder(id!))
        })()
    }, [])

    return (
        <>
            <Row>
                <Col md={8}>
                    <Card className='pt-4' title=''>
                        <Card.Body>
                            <div>
                                <h2 className='d-inline-block align-middle'>
                                    <a href={orderDetails?.shopifyUrl} className='text-white' target='_blank'>
                                        Order #{orderDetails?.orderNumber}
                                    </a>

                                    {orderDetails && TableUtilities.statusBadge(orderDetails?.status, 'ms-3 align-middle')}
                                </h2>

                                <p className='small text-muted fw-bolder'>{orderDetails && TableUtilities.formatDateLong(orderDetails.createdAt)}</p>
                            </div>

                            <div className='mt-4'>
                                {orderDetails?.orderItem?.map(i => (
                                    <div className='d-flex mt-7'>
                                        <div className='me-5 bg-white p-2 rounded'>
                                            <Link to={`/inventory/items/${i.item.id}`}>
                                                <div
                                                    style={{
                                                        backgroundImage: `url("${i.item.product.imageUrl}")`,
                                                        backgroundSize: 'contain',
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundPosition: 'center center',
                                                        height: '90px',
                                                        width: '90px'
                                                    }}>
                                                </div>
                                            </Link>
                                        </div>

                                        <div className='d-flex justify-content-center flex-column gap-1 w-100'>
                                            <span className='h1 text-dark fw-bolder mb-1 fs-5'>
                                                <Link className='text-white' to={`/inventory/items/${i.item.id}`}>
                                                    {i.item.product.title}
                                                </Link>

                                                <span className={'float-end'}>
                                                    {TableUtilities.formatCurrencyCents(i.subtotal)}
                                                </span>
                                            </span>

                                            <span className='text-muted text-capitalize'>
                                                {i.item.size} - {i.item.shape}

                                                <span className='text-muted text-capitalize float-end'>
                                                    Profit: {TableUtilities.formatCurrencyCents(i.profitTotal)}
                                                </span>
                                            </span>

                                            <span className='text-muted'>{i.item.product.sku}</span>
                                            <span>
                                                <span
                                                    className='badge badge-secondary text-capitalize min-w-75px justify-content-center'>
                                                    {i.item.storeOwned ? 'Store Owned' : i.item.user.fullName}
                                                </span>
                                                <span>
                                                    {TableUtilities.statusBadge(i.status, 'ms-3 float-end')}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={4}>

                    <Card className='pt-4' title=''>
                        <Card.Body>
                            <div className='pb-7'>
                                <h2>Order Summary</h2>
                                <div className='small text-muted fw-bolder text-capitalize'>
                                    Source: {orderDetails?.source}
                                </div>
                            </div>

                            <div className='d-flex flex-column gap-1'>
                                <div className='d-flex justify-content-between w-100 fs-6'>
                                    <p className='text-muted'>Subtotal ({orderDetails?.quantity} Items)</p>
                                    <p className='text-white'>{TableUtilities.formatCurrencyCents(orderDetails?.subtotal ?? 0)}</p>
                                </div>
                                <div className='d-flex justify-content-between w-100 fs-6'>
                                    <p className='text-muted'>Delivery</p>
                                    <p className='text-white'>{TableUtilities.formatCurrencyCents(orderDetails?.shippingTotal ?? 0)}</p>
                                </div>
                                <div className='d-flex justify-content-between w-100 fs-6'>
                                    <p className='text-muted'>Taxes</p>
                                    <p className='text-white'>{TableUtilities.formatCurrencyCents(orderDetails?.taxTotal ?? 0)}</p>
                                </div>
                                <div className='d-flex justify-content-between w-100 fs-6'>
                                    <p className='text-muted'>Total paid by customer</p>
                                    <p className='text-white'>{TableUtilities.formatCurrencyCents(orderDetails?.priceTotal ?? 0)}</p>
                                </div>
                            </div>
                        </Card.Body>

                        <Card.Footer className='pb-4'>

                            <div className='d-flex justify-content-between w-100 fs-5 text-white fw-bolder'>
                                <p>Total Profit</p>
                                <p>{TableUtilities.formatCurrencyCents(orderDetails?.profitTotal ?? 0)}</p>
                            </div>
                        </Card.Footer>

                    </Card>
                </Col>
            </Row>
        </>
    )
}

const ViewOrderPage = () => {
    const intl = useIntl()

    return (
        <>
            <PageTitle breadcrumbs={[{
                title: 'Orders',
                path: '/orders',
                isActive: false
            }]}>{intl.formatMessage({id: 'MENU.VIEW_ORDER'})}</PageTitle>
            <Helmet>
                <title>{intl.formatMessage({id: 'MENU.VIEW_ORDER'})} - Bind</title>
            </Helmet>
            <ViewOrderPageContent/>
        </>
    )
}

export default ViewOrderPage