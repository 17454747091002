import {useIntl} from "react-intl";
import {PageTitle} from "../../../_metronic/layout/core";
import React, {useState} from "react";
import {PaginatedTable, TableHeader} from "components";
import {Button, Card} from "react-bootstrap";
import TableUtilities from "components/dist/tables/table-utilities";
import CreateTierModal from "../../modules/dashboard/tiers/components/CreateTierModal";
import {Tier} from "../../modules/dashboard/consignors/consignors.service";
import {Helmet} from "react-helmet-async";

const TierPage = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedTier, setSelectedTier]  = useState<Tier | undefined>(undefined);
    const [tableKey, setTableKey] = useState<number>(567);

    const headers: TableHeader[] = [
        {key: 'name', label: 'Name', cellClassName: 'text-white'},
        {key: 'percent', label: 'Fee', format: (cell) => <>{cell.item}%</>},
        {key: 'minimumFee', label: 'Minimum Fee', rowClassName: 'max-w-50px', format: (cell) => TableUtilities.formatCurrencyCents(cell.item)},
        {key: 'default', label: 'Default', format: (cell) => <>{cell.item.toString()}</>},
        {key: 'id', label: 'Actions', format: (cell) => rowActions(cell.row as Tier)},
    ]

    const onTierChange = () => {
        setTableKey(tableKey * 56);
    }

    const updateTier = (tier: Tier) => {
        setSelectedTier(tier);
        setShowModal(true)
    }

    const openModal = () => {
        setSelectedTier(undefined)
        setShowModal(true)
    }

    const rowActions = (tier: Tier) => {
        return <Button variant='link' className='link-primary' onClick={() => updateTier(tier)} >Update</Button>
    }

    const tableActions = () => {
        return <Button variant='primary' onClick={() => openModal()}>
            Create Tier
        </Button>
    }

    return (
        <>
            <Card>
                <Card.Body>
                    <PaginatedTable
                        key={tableKey}
                        headers={headers}
                        dataProperty={'tiers'}
                        dataUrl={'/api/v1/tier/all'}
                        className={''}
                        actions={tableActions()}
                        filterLocally={true}
                    />
                </Card.Body>
            </Card>

            <CreateTierModal
                tier={selectedTier}
                show={showModal}
                onClose={() => setShowModal(false)}
                onTierChange={onTierChange}
            />
        </>
    )
}
const TierWrapper = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.TIERS'})}</PageTitle>
            <Helmet>
                <title>{intl.formatMessage({id: 'MENU.TIERS'})} - Bind</title>
            </Helmet>
            <TierPage/>
        </>
    )
}

export default TierWrapper;