import {useIntl} from "react-intl";
import {PageTitle} from "../../../_metronic/layout/core";
import React, {ChangeEvent, FormEvent, useCallback, useEffect, useState} from "react";
import {Button, Card, Col, Container, Dropdown, Form, InputGroup, Row} from "react-bootstrap";
import Dropzone, {useDropzone} from "react-dropzone";
import {TagsInput} from "react-tag-input-component";
import axios from "axios";
import {toast} from "react-hot-toast";
import InventoryService from "../../modules/dashboard/inventory/inventory.service";
import {useNavigate} from "react-router-dom";

interface Category {
    id: string
    name: string
}

const CreateProductPage = () => {
    const genders = ['Men', 'Women', 'Unisex'];
    const [categories, setCategories] = useState<Category[]>([]);

    const [sizes, setSizes] = useState<string[]>([]);
    const [title, setTitle] = useState<string>('');
    const [brand, setBrand] = useState<string>('');
    const [sku, setSku] = useState<string>('');
    const [categoryId, setCategoryId] = useState<string>('');
    const [gender, setGender] = useState<string>('');
    const [imageUrl, setImageUrl] = useState<string>('');

    const navigate = useNavigate();

    const fetchCategories = async () => {
        const response = await axios.get('/api/v1/category/names/all');

        setCategories(response.data.categoryNames)
    }

    const onFileUploaded = async (url: string) => {
        setImageUrl(url);
    }

    const createProduct = async (event: FormEvent) => {
        event.preventDefault();

        const data = {
            title,
            brand,
            sku,
            gender,
            sizes,
            category_id: categoryId,
            image_url: imageUrl,
        }

        toast.loading('Please wait...', {id: 'createProduct'})

        try {
            const response = await axios.post('/api/v1/product/create', data)

            toast.success(response.data.message.message, {
                id: 'createProduct',
                duration: 8000
            })

            navigate(`/catalog/${response.data.resourceId}`)
        } catch (e: any) {
            toast.error(e.response.data.details.message, {
                id: 'createProduct',
                duration: 5000
            })
        }
    }


    useEffect(() => {
        (async () => {
            await fetchCategories();
        })();
    }, []);


    return (
        <>
            <Row className='mh-100 justify-content-center'>
                <Col xs={'12'}>
                    <Card className='p-10'>
                        <Row className={'justify-content-center'}>
                            <Col xs={'12'} sm={'10'} md={'8'}>
                                <h1>Product Information</h1>

                                <Form onSubmit={createProduct} className='mt-10'>
                                    <Form.Group className="mb-10" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Title</Form.Label>
                                        <Form.Control
                                            value={title}
                                            required={true}
                                            className='form-control-solid'
                                            type="text"
                                            placeholder="Air Jordan 1 Retro High OG"
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
                                        />
                                    </Form.Group>

                                    <Row className="mb-10">
                                        <Form.Group as={Col} controlId="exampleForm.brand">
                                            <Form.Label>Brand</Form.Label>
                                            <Form.Control
                                                value={brand}
                                                required={true}
                                                className='form-control-solid'
                                                type="text"
                                                placeholder="Nike"
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => setBrand(e.target.value)}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="exampleForm.sku">
                                            <Form.Label>SKU</Form.Label>
                                            <Form.Control
                                                value={sku}
                                                required={true}
                                                className='form-control-solid'
                                                type="text"
                                                placeholder="CP9654"
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => setSku(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-10">
                                        <Form.Group as={Col} controlId="exampleForm.category">
                                            <Form.Label>Category</Form.Label>
                                            <Form.Select
                                                required={true}
                                                value={categoryId}
                                                onChange={(e: ChangeEvent<HTMLSelectElement>) => setCategoryId(e.target.value)}
                                                className='form-select form-select-solid'>
                                                <option value="">Select category...</option>
                                                {categories.map(category => {
                                                    return (
                                                        <option value={category.id}>
                                                            {category.name}
                                                        </option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="exampleForm.gender">
                                            <Form.Label>Gender</Form.Label>
                                            <Form.Select
                                                value={gender}
                                                required={true}
                                                onChange={(e: ChangeEvent<HTMLSelectElement>) => setGender(e.target.value)}
                                                className='form-select form-select-solid'>
                                                <option value="">Select gender...</option>
                                                {genders.map(g => {
                                                    return (
                                                        <option>{g}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                    </Row>

                                    <div className={'mb-10'}>
                                        <Form.Label className='mb-2'>Product Image</Form.Label>
                                        <ImageUpload onFileUploaded={onFileUploaded}/>
                                    </div>

                                    <div>
                                        <Form.Label className='mb-2'>Sizes</Form.Label>
                                        <span className="d-block text-muted mb-2">
                                            Enter sizes one at a time, pressing Enter after each. These sizes will be available when creating new items.
                                        </span>

                                        <TagsInput
                                            value={sizes}
                                            onChange={setSizes}
                                            name="fruits"
                                            placeHolder="Enter size"
                                        />
                                    </div>

                                    <div className='text-end mt-10'>
                                        <Button variant={'secondary'} className='col-x-6 col-sm-3'>Cancel</Button>
                                        <Button className={'ms-4 col-x-6 col-sm-3'} type={"submit"}>Submit</Button>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

interface ImageUploadProps {
    onFileUploaded: (url: string) => void,
}

const ImageUpload = ({onFileUploaded}: ImageUploadProps) => {
    const [selectedImage, setSelectedImage] = useState<string | ArrayBuffer | null>(null);

    interface ImageFile extends File {
        preview: string;
    }

    const uploadToS3 = async (file: File) => {
        try {
            const response = await axios.post('/api/v1/upload/generate', {
                file_type: file.type
            });

            const presignedUrl = response.data.url;

            const uploadResponse = await axios.put(presignedUrl, file, {
                headers: {
                    'Content-Type': file.type,
                    'x-amz-acl': 'public-read'
                },
            });

            onFileUploaded(response.data.fileUrl)

            console.log('File uploaded successfully.');
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const handleDrop = (acceptedFiles: File[]): void => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0] as ImageFile;
            const reader = new FileReader();

            reader.onload = (event) => {
                if (event.target?.result) {
                    setSelectedImage(event.target.result);
                    uploadToS3(file); // Call the upload function after setting the image
                }
            };

            reader.readAsDataURL(file);
        }
    };

    return (
        <>
            <Dropzone onDrop={handleDrop} accept={{'image/png': [], 'image/jpg': [], 'image/jpeg': []}}>
                {({getRootProps, getInputProps}) => (
                    <section className='dropzone'>
                        <div {...getRootProps()}>
                            <input type='file' id='exampleForm.image' {...getInputProps()} />
                            {selectedImage ? (
                                <div className="square-image bg-secondary rounded-3 max-h-100px">
                                    <img src={selectedImage.toString()} alt="Preview"
                                         style={{width: "100%", height: "auto"}}/>
                                </div>
                            ) : (
                                <>
                                    <span className='symbol symbol-40px'>
                                        <span className='symbol-label bg-secondary rounded-3'></span>
                                    </span>
                                    <p className='mb-2 mt-4 text-secondary text-gray-700'>Drag and drop a file to
                                        upload</p>
                                    <span className='text-muted'>JPEG or PNG</span>
                                </>
                            )}
                        </div>
                    </section>
                )}
            </Dropzone>
        </>
    )
}

const CreateProductWrapper = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.CREATE_PRODUCT'})}</PageTitle>
            <CreateProductPage/>
        </>
    )
}


export default CreateProductWrapper