import {useIntl} from "react-intl";
import {useState} from "react";
import {Card, Button} from "react-bootstrap";
import {toast} from "react-hot-toast";
import axios from "axios";
import {Link} from "react-router-dom"

import {PageTitle} from "../../../../_metronic/layout/core";
import {Helmet} from "react-helmet-async";
import {ConfirmModal, PaginatedTable, TableHeader} from "components";
import TableUtilities from "components/dist/tables/table-utilities";


interface Payment {
    creatorId: string
}


const Page = () => {
    const [childKey, setChildKey] = useState<number>(549 * 61);
    const [selectedItems, setSelectedItems] = useState<Payment[]>([]);
    const [showConfirm, setShowConfirm] = useState<boolean>(false);

    const onCancel = () => {
        setShowConfirm(false);
    }

    const onConfirm = async () => {
        toast.loading("Please wait...", {id: "payout"})
    
        setShowConfirm(false);
    
        try {
            const response = await axios.post("/api/v1/payout/create", {
                consignor_id: selectedItems.map(i => i.creatorId)
            })
    
            // Update the child key to force a re-render/re-fetch of the table data.
            setChildKey(childKey * 89);
    
            toast.success(response.data.message, {
                id: "payout",
                duration: 8000
            })
        } catch (e: any) {
            toast.error(e.response.data.details.message, {
                id: "payout",
                duration: 5000
            })
        }
    }

    const headers: TableHeader[] = [
        {
            key: "fullName",
            label: "Consigner",
            format: (cell) => {
                return (
                    <Link className="text-grey" to={`/payouts/${cell.row.creatorId}`}>
                        {cell.row.fullName}
                    </Link>
                )
            }
        },
        {key: "email", label: "Email"},
        {key: "payoutCount", label: "Items"},
        {
            key: "payoutAmountSubtotal",
            label: "Payout Amount",
            format: (cell) => TableUtilities.formatCurrencyCents(cell.item),
            cellClassName: "text-white"
        },
        {
            key: "profitSubtotal",
            label: "Store Profit",
            format: (cell) => TableUtilities.formatCurrencyCents(cell.item),
        }
    ]

    const onItemsSelected = (items: any[]) => {
        setSelectedItems(items);
    }

    const tableActions = () => (
        <>
            <div className="d-flex align-items-center gap-10">
                {selectedItems.length > 0 && (
                    <>
                        <span className="fw-bold">
                            {selectedItems.length} Payouts Selected
                        </span>
                        <Button variant={"primary"} onClick={() => setShowConfirm(true)}>Pay Consigners</Button>
                    </>
                )}
            </div>
        </>
    )

    return (
        <>
            <Card>
                <Card.Body className="pt-5">
                    <PaginatedTable
                        key={null}
                        headers={headers}
                        dataProperty="payouts"
                        method="get"
                        dataUrl="/api/v1/payout/awaiting/all"
                        className={""}
                        actions={tableActions()}
                        paginate={true}
                        selectable={true}
                        onSelected={onItemsSelected}
                        selectedItems={selectedItems}
                        filterLocally={true}
                    />

                    <ConfirmModal
                        visible={showConfirm}
                        message={"Are you sure you want to process these payments?"}
                        content={
                            `${selectedItems.length} payouts for a total amount of ${TableUtilities.formatCurrencyCents(selectedItems.reduce((total, s: any) => total + Number(s.payoutAmountSubtotal), 0))}`
                        }
                        cancelButtonText={"Cancel"}
                        onConfirm={onConfirm}
                        onCancel={onCancel}
                    />
                </Card.Body>
            </Card>
        </>
    )
}

const PayoutAwaitingWrapper = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: "MENU.PAYOUT_AWAITING"})}</PageTitle>
            <Helmet>
                <title>{intl.formatMessage({id: "MENU.PAYOUT_AWAITING"})} - Bind</title>
            </Helmet>
            <Page/>
        </>
    )
}
export default PayoutAwaitingWrapper;