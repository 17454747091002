import axios from "axios";

const OrdersService = {

    fetchOrder: async (orderId: string) : Promise<OrderDetails> => {
        const response = await axios.get(`/api/v1/order/view/${orderId}`)

        return response.data
    }
}

export enum OrderStatus {
    All = '',
    Unfulfilled = 'unfulfilled',
    Partially_Fulfilled = 'partially_fulfilled',
    Fulfilled = 'fulfilled',
    Cancelled = 'cancelled'
}

export enum OrderSource {
    All = '',
    Point_Of_Sale = 'pos',
    Web = 'web'
}

export interface OrderDetails {
    id: string
    orderNumber: string
    createdAt: string
    subtotal: number
    shippingTotal: number
    priceTotal: number
    profitTotal: number
    taxTotal: number
    quantity: number
    currency: string
    status: string
    source: string
    orderItem: OrderItem[]
    shopifyUrl: string
}

interface OrderItem {
    id: string;
    created_at: string;
    profitTotal: number;
    subtotal: number;
    status: string;
    item: ProductItem;
}

interface ProductItem {
    id: string;
    createdAt: string;
    storeOwned: boolean;
    size: string;
    price: number;
    shape: string;
    status: string;
    product: ProductDetails;
    user: User;
}

interface ProductDetails {
    id: string;
    createdAt: string;
    title: string;
    primaryTitle: string;
    secondaryTitle: string;
    brand: string;
    sku: string;
    colorway: string;
    gender: string;
    releaseDate: string;
    imageUrl: string;
}

interface User {
    id: string;
    firstName: string;
    lastName: string;
    userType: string;
    fullName: string;
}

export default OrdersService