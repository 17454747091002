import {ConfirmModal, PaginatedTable, TableHeader} from "components";
import Card from "../../modules/dashboard/dashboard/components/Card";
import React, {useState} from "react";
import TableUtilities from "components/dist/tables/table-utilities";
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Consignor} from "../../modules/dashboard/consignors/consignors.service";
import {toast} from "react-hot-toast";
import axios from "axios";
import ConsignorService from "./ConsignorService";
import {PageTitle} from "../../../_metronic/layout/core";
import {useIntl} from "react-intl";
import {Helmet} from "react-helmet-async";

const ConsignorsPage = () => {
    const [selectedConsignors, setSelectedConsignors] = useState<Consignor[]>([])
    const [messageVisible, setMessageVisible] = useState<boolean>(false);

    const onDisableConsignors = async () => {
        await ConsignorService.disableConsignors(selectedConsignors.map(c => c.id));
    }

    const headers: TableHeader[] = [
        {
            key: 'fullName',
            label: 'Name',
            rowClassName: 'min-w-125px',
            format: (cell) => <Link className='text-white' to={`${cell.row.id}`}>{cell.item}</Link>
        },
        {key: 'email', label: 'Email', rowClassName: 'min-w-125px'},
        {
            key: 'phoneNumber', 
            label: 'Phone Number', 
            format: (cell) => cell.item || '-'
        },
        {key: 'userRef', label: 'Reference'},
        {key: 'tier.name', label: 'Tier',},
        {key: 'createdAt', label: 'Joined Date', format: (cell) => TableUtilities.formatDate(cell.item)},
        {key: 'status', label: 'Status', format: (cell) => TableUtilities.statusBadge(cell.item)},
    ]

    const onItemsSelected = (items: any[]) => {
        setSelectedConsignors(items as Consignor[]);
    }

    const tableActions = () => (
        <>
            <div className='d-flex align-items-center gap-10'>
                {selectedConsignors.length > 0 && (
                    <>
                        <span className='fw-bold'>
                            {selectedConsignors.length} Items Selected
                        </span>

                        <Button variant={'primary'} onClick={() => setMessageVisible(true)}>
                            Disable Consignor
                        </Button>
                    </>
                )}
            </div>
        </>
    )

    const intl = useIntl()

    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.CONSIGNORS'})}</PageTitle>

            <Helmet>
                <title>{intl.formatMessage({id: 'MENU.CONSIGNORS'})} - Bind</title>
            </Helmet>

            <Card className={'p-5'} title=''>
                <PaginatedTable
                    // selectable={true}
                    className={'mt-5'}
                    paginate={true}
                    dataProperty={'consignors'}
                    headers={headers}
                    actions={tableActions()}
                    onSelected={onItemsSelected}
                    selectedItems={selectedConsignors}
                    dataUrl={'/api/v1/user/consignor/all'}
                    filterLocally={true}
                />
            </Card>

            <ConfirmModal
                visible={messageVisible}
                onConfirm={onDisableConsignors}
                message={`Are you sure you want to disable ${selectedConsignors.length} consignors?`}
                onCancel={() => setMessageVisible(false)}
            />
        </>
    )
}

export default ConsignorsPage