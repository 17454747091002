import axios from "axios";
import {EmployeeEvent} from "./models/employee_event";

const EmployeesService = {
    fetchEmployees: async (): Promise<Employee[]> => {
        const response = await axios.get('/api/v1/user/employee/all')

        return response.data.employees;
    },
    fetchEmployee: async (id: string) : Promise<Employee> => {
        const response = await axios.get(`/api/v1/user/employee/view/${id}`)

        return response.data;
    },
    fetchEvents: async (id: string) : Promise<EmployeeEvent[]> => {
        const response = await axios.get(`/api/v1/event/user/${id}`)

        return response.data.events
    },
    createEmployee: async (email: string, firstName: string, lastName: string) => {
        const response = await axios.post('/api/v1/user/employee/create', {
            email: email,
            full_name: `${firstName} ${lastName}`,
        })

        // Will throw if errored
        return true
    }
}

export interface Employee {
    id: string
    createdAt: string
    fullName: string
    status: string
    userType: string
}

export default EmployeesService;