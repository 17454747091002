import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import React, {ChangeEvent, useEffect, useState} from "react";
import ConsignorsService, {Consignor, Tier} from "../consignors.service";
import {Simulate} from "react-dom/test-utils";
import select = Simulate.select;
import {toast, Toaster} from "react-hot-toast";
import axios from "axios";

type Props = {
    show: boolean,
    onClose: (update: boolean) => void,
    onEmployeeCreated?: () => void,
    consignor: Consignor
}


const EditConsignorModal: React.FC<Props> = ({consignor, show, onClose, onEmployeeCreated}) => {
    const [tiers, setTiers] = useState<Tier[]>([])
    const [selectedTier, setSelectedTier] = useState<Tier>(consignor.tier)


    useEffect(() => {
        (async () => {
            setTiers(await ConsignorsService.fetchTiers());
        })()
    }, []);

    const handleClose = () => onClose(false);

    const resetForm = () => {
        setSelectedTier(consignor.tier)
    }

    const handleTierChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const tier = tiers.find(t => t.id === event.target.value)

        if(!tier) return;

        setSelectedTier(tier)
    }

    const updateConsignor = async (e: React.FormEvent) => {
        e.preventDefault();

        // If trying to update to the tier that is already selected, return to prevent the call
        if(consignor.id === selectedTier.id) return;

        toast.loading('Please wait...', {id: 'updateConsignor'})

        try {
            const response = await ConsignorsService.updateConsignorTier(consignor.id, selectedTier.id)

            toast.success(response.data.message, {
                id: 'updateConsignor',
                duration: 8000
            })
        } catch(e: any) {
            toast.error(e.response.data.details.message, {
                id: 'updateConsignor',
                duration: 5000
            })
        }

        onClose(true);
    };

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton className={'border-0 pb-0'}>
                </Modal.Header>
                <Modal.Body className='pt-10'>
                    <h2 className='text-center mb-10 fw-bolder fs-1'>Update Consignor</h2>

                    <Form className='mt-10 p-4' onSubmit={updateConsignor}>
                        <Form.Group className='pb-3' controlId="tier">
                            <Form.Label>Seller Tier</Form.Label>

                            <Form.Select
                                className='form-select form-select-solid mt-3'
                                id='tier'
                                aria-label="Default select example"
                                value={selectedTier.id}
                                onChange={handleTierChange}>
                                {tiers.map(l => {
                                    return (
                                        <option value={l.id}>{l.name}</option>
                                    )
                                })}
                            </Form.Select>
                        </Form.Group>

                        <Button variant="primary" className='w-100 max-w-100 mt-10 mb-10' type='submit'>
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EditConsignorModal;