import {useIntl} from "react-intl";
import {PageTitle} from "../../../_metronic/layout/core";
import React, {useEffect, useState} from "react";
import Card from "../../modules/dashboard/dashboard/components/Card";
import {Button} from "react-bootstrap";
import EmployeesService, {Employee} from "../../modules/dashboard/employees/employees.service";
import TableUtilities from "components/dist/tables/table-utilities";
import {ConfirmModal, OutlineStat} from "components";
import {
    CategoryEvent,
    EmployeeEvent,
    EventType,
    ItemEvent, LocationEvent, ProductEvent, TierEvent
} from "../../modules/dashboard/employees/models/employee_event";
import {useParams} from "react-router-dom";
import ConsignorService from "../consignors/ConsignorService";
import {toast} from "react-hot-toast";
import axios from "axios";
import {Helmet} from "react-helmet-async";

const EmployeePage = () => {
    const [employee, setEmployee] = useState<Employee | undefined>(undefined);
    const [events, setEvents] = useState<EmployeeEvent[]>([])
    const [messageVisible, setMessageVisible] = useState<boolean>(false);

    const onDisableEmployee = async () => {
        toast.loading('Please wait...', {id: 'disableEmployee'})

        try {
            const response = await axios.post('/api/v1/user/employee/disable', {
                'user_id': employee?.id
            })

            toast.success(response.data.message, {
                id: 'disableEmployee',
                duration: 8000
            })
        } catch (e: any) {
            toast.error('Oops something went wrong. Please try again.', {
                id: 'disableEmployee',
                duration: 5000
            })

            throw e;
        }
    }

    const {id} = useParams();

    useEffect(() => {
        (async () => {
            const employee = await EmployeesService.fetchEmployee(id!)
            const events = await EmployeesService.fetchEvents(id!)

            setEmployee(employee)
            setEvents(events)
        })()
    }, [])

    const getEventSubtitle = (event: EmployeeEvent) => {
        switch (event.eventType) {
            case EventType.ITEM_WITHDRAWN:
            case EventType.ITEM_WITHDRAWAL_CREATED:
            case EventType.ITEM_ACCEPTED:
            case EventType.ITEM_PRICE_UPDATE:
            case EventType.ITEM_ONBOARDED:
                return (event as ItemEvent).item.product.title
            case EventType.CATEGORY_UPDATED:
                return (event as CategoryEvent).category.name
            case EventType.LOCATION_UPDATED:
                return (event as LocationEvent).location.name
            case EventType.TIER_UPDATED:
            case EventType.TIER_CREATED:
                return (event as TierEvent).tier.name
            case EventType.PRODUCT_CREATED:
                return (event as ProductEvent).product.title
        }
    }

    return (
        <>
            <Card className={'p-3 pt-10 p-md-10 pb-md-0'} title=''>
                <div className='d-flex justify-content-between'>
                    <div>
                        <div className='d-flex align-middle'>
                            <h2 className='mb-0'>
                                {employee?.fullName}
                            </h2>

                            <div className='d-block d-md-inline ms-0 ms-md-3'>

                                <span
                                    className={`badge ${employee?.status === 'active' ? 'badge-light-success' : 'badge-light-danger'} text-capitalize min-w-75px justify-content-center`}>
                                    {employee?.status}
                                </span>
                            </div>
                        </div>
                    </div>


                    <div>
                        <Button variant='primary' className='ml-auto' onClick={() => setMessageVisible(true)}>
                            Disable
                            <span className='d-none d-md-inline'>&nbsp;Employee</span>
                        </Button>
                    </div>
                </div>

                <div className='d-flex flex-row flex-wrap mt-15'>
                    <OutlineStat title={'Events'} value={events.length.toString()}/>
                </div>
            </Card>

            <Card className={'p-2 pt-5'} title={''}>
                <h2 className='mt-5'>Events</h2>

                <ul className="timeline mb-5">
                    {events.map((item, index) => (
                        <li key={index}>
                            <small className='text-muted'>{getEventSubtitle(item)}</small>
                            <span className={'fw-bold d-block'}>{item.title}</span>
                            <p className='text-muted'>{item.description}</p>
                            <small className='fw-medium text-muted'>{TableUtilities.formatDateLong(item.createdAt)}</small>
                        </li>
                    ))}
                </ul>
            </Card>


            <ConfirmModal
                visible={messageVisible}
                onConfirm={onDisableEmployee}
                message={`Are you sure you want to disable this employee?`}
                onCancel={() => setMessageVisible(false)}
            />
        </>
    )
}


const EmployeeWrapper = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[{
                title: 'Employees',
                path: '/employees',
                isActive: false
            }]}>{intl.formatMessage({id: 'MENU.VIEW_EMPLOYEE'})}
            </PageTitle>
            <Helmet>
                <title>{intl.formatMessage({id: 'MENU.VIEW_EMPLOYEE'})} - Bind</title>
            </Helmet>
            <EmployeePage/>
        </>
    )
}


export default EmployeeWrapper