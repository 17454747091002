import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import React, {ChangeEvent, useEffect, useState} from "react";
import {toast, Toaster} from "react-hot-toast";
import axios from "axios";
import {Location} from "../../inventory/inventory.service";
import locations from "../../../../pages/settings/locations";

type Props = {
    location?: Location
    show: boolean,
    onClose: () => void,
    onLocationChange: () => void
}


const CreateLocationModal: React.FC<Props> = ({location, show, onClose, onLocationChange}) => {
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [addressTwo, setAddressTwo] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [shopifyLocation, setShopifyLocation] = useState('');

    useEffect(() => {
        setName(location?.name || '')
        setAddress(location?.addressOne || '')
        setAddressTwo(location?.addressTwo || '')
        setZipCode(location?.zipCode || '')
        setCity(location?.city || '')
        setState(location?.state || '')
        setCountry(location?.country || '')
        setShopifyLocation(location?.shopifyLocationId || '')
    }, [location]);

    const handleClose = () => onClose();

    const updateConsignor = async (e: React.FormEvent) => {
        e.preventDefault();

        toast.loading('Please wait...', {id: 'createLocation'})

        const locationData = {
            name: name,
            address_one: address,
            address_two: addressTwo,
            zip_code: zipCode,
            city: city,
            state: state,
            country: country,
            shopify_location_id: shopifyLocation
        }

        try {
            const response = await axios({
                method: !!location ? 'patch' : 'post',
                url: !!location ? '/api/v1/location/update' : '/api/v1/location/create',
                data: {
                    ...!!location && {
                        location_id: location.id,
                        location: locationData
                    },
                    ...!location && locationData
                }
            })

            onLocationChange()
            handleClose()

            toast.success(response.data.message, {
                id: 'createLocation',
                duration: 8000
            })
        } catch (e: any) {
            toast.error(e.response.data.details.message, {
                id: 'createLocation',
                duration: 5000
            })
        }
    };

    const modalTitle = location ? 'Update Location' : 'Create Location';

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton className={'border-0 pb-0'}>
                </Modal.Header>
                <Modal.Body className='pt-10'>
                    <h2 className='text-center mb-10 fw-bolder fs-1'>{modalTitle}</h2>

                    <Form className='mt-10' onSubmit={updateConsignor}>
                        {/* Bind the input fields to the state */}
                        <Form.Group className="mb-2 p-4" controlId="name">
                            <Form.Label className='fw-bold'>Name</Form.Label>
                            <Form.Control
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className='form-control-solid'
                                placeholder='Flagship Store'
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-2 p-4" controlId="address">
                            <Form.Label className='fw-bold'>Address One</Form.Label>
                            <Form.Control
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                className='form-control-solid'
                                placeholder='159 Locust St.'
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-2 p-4" controlId="addressTwo">
                            <Form.Label className='fw-bold'>Address Two</Form.Label>
                            <Form.Control
                                value={addressTwo}
                                onChange={(e) => setAddressTwo(e.target.value)}
                                className='form-control-solid'
                                placeholder='Optional'
                            />
                        </Form.Group>
                        <Form.Group className="mb-2 p-4" controlId="zipCode">
                            <Form.Label className='fw-bold'>ZIP Code</Form.Label>
                            <Form.Control
                                value={zipCode}
                                onChange={(e) => setZipCode(e.target.value)}
                                className='form-control-solid'
                                placeholder='90210'
                                required
                            />
                        </Form.Group>
                        <Row className="mb-2 p-4">
                            <Form.Group as={Col} controlId="city">
                                <Form.Label className='fw-bold'>City</Form.Label>
                                <Form.Control
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                    className='form-control-solid'
                                    placeholder='Los Angeles'
                                    required
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="state">
                                <Form.Label className='fw-bold'>State</Form.Label>
                                <Form.Control
                                    value={state}
                                    onChange={(e) => setState(e.target.value)}
                                    className='form-control-solid'
                                    placeholder='California'
                                    required
                                />
                            </Form.Group>
                        </Row>
                        <Form.Group className="mb-2 p-4" controlId="country">
                            <Form.Label className='fw-bold'>United States</Form.Label>
                            <Form.Control
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                                className='form-control-solid'
                                placeholder='United States'
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-2 p-4" controlId="shopifyLocation">
                            <Form.Label className='fw-bold'>Shopify Location ID</Form.Label>
                            <Form.Control
                                value={shopifyLocation}
                                onChange={(e) => setShopifyLocation(e.target.value)}
                                className='form-control-solid'
                                placeholder='gid://shopify/Location/00000000000'
                                required
                            />
                        </Form.Group>

                        <Form.Group className='px-4'>
                            <Button variant="primary" className='w-100 max-w-100 mt-10 mb-10' type='submit'>
                                Submit
                            </Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CreateLocationModal;