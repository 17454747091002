import TableUtilities from "components/dist/tables/table-utilities";
import {ConfirmModal} from "components";
import React, {ChangeEvent, ChangeEventHandler, useState} from "react";
import {FormControl} from "react-bootstrap";
import InventoryService, {InventoryItem} from "../../../modules/dashboard/inventory/inventory.service";
import {toast} from "react-hot-toast";

export interface Props {
    showModal: boolean,
    onClose: () => void,
    selectedItems: InventoryItem[]
}

export default ({showModal, onClose, selectedItems}: Props) => {

    const [reason, setReason] = useState<string>('');

    const onConfirm = async () => {
        toast.loading('Please wait...', {id: 'acceptItems'})

        try {
            const response = await InventoryService.declineItems(selectedItems.map(s => s.id), reason)

            toast.success(response.data.message, {
                id: 'acceptItems',
                duration: 8000
            })

            onClose();
        } catch (e: any) {
            toast.error(e.response.data.details.message, {
                id: 'acceptItems',
                duration: 5000
            })
        }
    }

    const onReasonChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setReason(e.target.value);
    }

    const onCancel = () => {
        onClose()
    }

    return (
        <>
            <ConfirmModal
                visible={showModal}
                message={`Are you sure you want to decline ${selectedItems.length} items?`}
                cancelButtonText={'Cancel'}
                onConfirm={onConfirm}
                onCancel={onCancel}
            >
                <div className='my-12'>
                    <label className='fw-bold text-gray-800 mb-3'>Decline Reason</label>

                    <textarea
                        value={reason}
                        onChange={onReasonChange}
                        rows={5}
                        placeholder='Describe why the item is being declined, the reasoning will be provided to the consignor in an email.'
                        className='form-control form-control-lg form-control-solid'>
                </textarea>
                </div>
            </ConfirmModal>
        </>
    )
}
