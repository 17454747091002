import {useIntl} from 'react-intl'
import Card from '../../modules/dashboard/dashboard/components/Card'
import {OrdersTable} from '../../modules/orders/OrdersTable'
import {PageTitle} from '../../../_metronic/layout/core'
import React, {useState} from 'react'
import {PaginatedTable, TableHeader} from 'components'
import TableUtilities from 'components/dist/tables/table-utilities'
import InventoryFilterDropdown from '../../modules/dashboard/inventory/components/InventoryFilterDropdown'
import OrdersFilterDropdown from '../../modules/dashboard/orders/components/OrdersFilterDropdown'
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet-async'

const OrdersPageContent = () => {
  const [tableFilters, setTableFilters] = useState<Record<string, string>>({})

  const headers: TableHeader[] = [
    {
      key: 'orderNumber',
      label: 'Order Id',
      format: (cell) => {
        return (
          <Link className='text-grey' to={`/orders/${cell.row.id}`}>
            {cell.item}
          </Link>
        )
      },
    },
    {key: 'createdAt', label: 'Sale Date', format: (cell) => TableUtilities.formatDate(cell.item)},
    {
      key: 'source',
      label: 'Sale Source',
      cellClassName: 'text-capitalize',
      format: (cell) => formatSaleSource(cell.item),
    },
    {key: 'quantity', label: 'Quantity'},
    {
      key: 'subtotal',
      label: 'Subtotal',
      format: (cell) => TableUtilities.formatCurrency(cell.item / 100),
    },
    {
      key: 'profitTotal',
      label: 'Profit',
      format: (cell) => TableUtilities.formatCurrency(cell.item / 100),
    },
    {key: 'status', label: 'Status', format: (cell) => TableUtilities.statusBadge(cell.item)},
  ]

  const formatSaleSource = (source: string) => {
    if (source.toLowerCase() === 'pos') return source.toUpperCase()

    return source
  }

  const onFilterChange = (filters: Record<string, string>) => {
    setTableFilters(filters)
  }

  const tableActions = () => (
    <>
      <div className='d-flex'>
        <OrdersFilterDropdown onFilterChange={onFilterChange} />
      </div>
    </>
  )

  return (
    <>
      <Card className='pt-10' title=''>
        <PaginatedTable
          className=''
          actions={tableActions()}
          headers={headers}
          paginate={true}
          method={'post'}
          filters={tableFilters}
          dataUrl={'/api/v1/order/management/search'}
        />
      </Card>
    </>
  )
}

const OrdersPage = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.ORDERS'})}</PageTitle>
      <Helmet>
        <title>Orders - Bind</title>
      </Helmet>
      <OrdersPageContent />
    </>
  )
}

export default OrdersPage
