import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import React, {ChangeEvent, useEffect, useState} from "react";
import {toast, Toaster} from "react-hot-toast";
import axios from "axios";
import {Category} from "components";

type Props = {
    category?: Category,
    show: boolean,
    onClose: () => void,
    onCategoryChange: () => void
}


const CreateCategoryModal: React.FC<Props> = ({category, show, onClose, onCategoryChange}) => {

    const [categoryName, setCategoryName] = useState('');
    const [weight, setWeight] = useState('');

    useEffect(() => {
        setCategoryName(category?.name ?? '')
        setWeight(category?.weight ?? '')
    }, [category]);

    const handleClose = () => onClose();

    const updateConsignor = async (e: React.FormEvent) => {
        e.preventDefault();

        toast.loading('Please wait...', {id: 'createCategory'})

        try {
            const response = await axios({
                method: !!category ? 'patch' : 'post',
                url: !!category ? '/api/v1/category/update' : '/api/v1/category/create',
                data: {
                    ...category && {category_id: category.id},
                    name: categoryName,
                    weight: weight,
                }
            })

            onCategoryChange()
            handleClose()

            toast.success(response.data.message, {
                id: 'createCategory',
                duration: 8000
            })
        } catch (e: any) {
            toast.error(e.response.data.details.message, {
                id: 'createCategory',
                duration: 5000
            })
        }
    };

    const modalTitle = category ? 'Update Category' : 'Create Category';

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton className={'border-0 pb-0'}>
                </Modal.Header>
                <Modal.Body className='pt-10'>
                    <h2 className='text-center mb-10 fw-bolder fs-1'>{modalTitle}</h2>

                    <Form className='mt-10' onSubmit={updateConsignor}>
                        <Form.Group className="mb-2 p-4" controlId="categoryName">
                            <Form.Label className='fw-bold'>Category Name</Form.Label>
                            <Form.Control
                                value={categoryName}
                                onChange={(e) => setCategoryName(e.target.value)}
                                className='form-control-solid'
                                placeholder='Sneakers'
                                disabled={!!category}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-2 p-4" controlId="weight">
                            <Form.Label className='fw-bold'>Weight (grams)</Form.Label>
                            <Form.Control
                                value={weight}
                                onChange={(e) => setWeight(e.target.value)}
                                className='form-control-solid'
                                placeholder='1200'
                                required
                            />
                        </Form.Group>


                        <Form.Group className="px-4">
                            <Button variant="primary" className='w-100 max-w-100 mt-10 mb-10' type='submit'>
                                Submit
                            </Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CreateCategoryModal;