/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'

type Props = {
  className: string
}

const ListsWidget6: React.FC<Props> = ({className}) => {
  return (
    <div className='card card-xl mb-5 mb-xl-8'>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bold text-dark'>Item Ownership</h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-0'>
        {/* begin::Item */}
        <div className='d-flex align-items-center bg-light-success rounded p-5 mb-7'>
          {/* begin::Icon */}
          <span className=' text-success me-5'>
            <KTIcon iconName='up-square' className='text-success fs-1 me-5' />
          </span>
          {/* end::Icon */}
          {/* begin::Title */}
          <div className='flex-grow-1 me-2'>
            <a className='fw-bold text-gray-800 fs-6'>
              Store Owned
            </a>
            <span className='text-muted fw-semibold d-block'>Assets</span>
          </div>
          {/* end::Title */}
          {/* begin::Lable */}
          <span className='fw-bold text-success py-1'>1,230</span>
          {/* end::Lable */}
        </div>
        {/* end::Item */}
        {/* begin::Item */}
        <div className='d-flex align-items-center bg-light-danger rounded p-5 mb-7'>
          {/* begin::Icon */}
          <span className=' text-danger me-5'>
            <KTIcon iconName='down-square' className='text-danger fs-1 me-5' />
          </span>
          {/* end::Icon */}
          {/* begin::Title */}
          <div className='flex-grow-1 me-2'>
            <a className='fw-bold text-gray-800 fs-6'>
              Consignor Owned
            </a>
            <span className='text-muted fw-semibold d-block'>Liabilities</span>
          </div>
          {/* end::Title */}
          {/* begin::Lable */}
          <span className='fw-bold text-danger py-1'>861</span>
          {/* end::Lable */}
        </div>
        {/* end::Item */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ListsWidget6}
