import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {Button, Dropdown, Form} from "react-bootstrap";
import {OrderSource, OrderStatus} from "../orders.service";

interface Props {
    onFilterChange: (filters: Record<string, string>) => void,
}


const OrdersFilterDropdown: React.FC<Props> = ({onFilterChange,}) => {
    const [isOpen, setIsOpen] = useState(false);

    const [source, setSource] = useState<OrderSource>(OrderSource.All);
    const [status, setStatus] = useState<OrderStatus>(OrderStatus.All);

    const handleSourceChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setSource(event.target.value as OrderSource)
    }

    const handleStatusChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setStatus(event.target.value as OrderStatus)
    }

    const applyFilters = (close = true) => {
        onFilterChange({
            ...source && {'source': source},
            ...status && {'status': status},
        })

        if (close)
            handleClose();
    }

    const resetFilters = () => {
        setSource(OrderSource.All)
        setStatus(OrderStatus.All)
    }

    const handleClose = () => setIsOpen(false);
    const handleOpen = () => setIsOpen(true);

    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            // Check if the click was outside the Dropdown
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    const handleToggleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // Toggle the dropdown's open/closed state
        setIsOpen(prevOpen => !prevOpen);
    };

    return (
        <>
            <Dropdown show={isOpen} onToggle={handleOpen} ref={dropdownRef}>
                <Dropdown.Toggle variant="light" id="dropdown-basic" onClick={handleToggleClick}>
                    <i className="ki-duotone ki-filter fs-2"><span className="path1"></span><span
                        className="path2"></span></i>
                    Filter
                </Dropdown.Toggle>

                <Dropdown.Menu className={'w-400px px-2 py-4'}>
                    <Dropdown.Header>
                        <span className='text-white fw-bold h4'>Filter Options</span>
                    </Dropdown.Header>

                    <Dropdown.Divider/>

                    <Form.Group className="mb-3 p-4" controlId="status">
                        <Form.Label>Status</Form.Label>
                        <Form.Select
                            value={status}
                            onChange={handleStatusChange}
                            className='form-select form-select-solid'>
                            {Object.entries(OrderStatus).map(([key, value]) => {
                                return (
                                    <option value={value}>{key.replaceAll('_', ' ')}</option>
                                )
                            })}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3 p-5" controlId="source">
                        <Form.Label>Source</Form.Label>
                        <Form.Select
                            value={source}
                            onChange={handleSourceChange}
                            className='form-select form-select-solid'>
                            {Object.entries(OrderSource).map(([key, value]) => {
                                return (
                                    <option value={value}>{key.replaceAll('_', ' ')}</option>
                                )
                            })}
                        </Form.Select>
                    </Form.Group>

                    <div className='d-flex justify-content-end pe-4'>
                        <Button variant={'secondary'} className='me-3' onClick={resetFilters}>Reset</Button>
                        <Button variant={'primary'} onClick={() => applyFilters(true)}>Apply</Button>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

export default OrdersFilterDropdown;