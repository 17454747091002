import {useIntl} from "react-intl";
import {PageTitle} from "../../../_metronic/layout/core";
import React, {useRef, useState} from "react";
import Card from "../../modules/dashboard/dashboard/components/Card";
import CreateEmployeeModal from "../../modules/dashboard/employees/components/CreateEmployeeModal";
import {PaginatedTable, PaginatedTableComponentHandles, TableHeader} from "components";
import TableUtilities from "components/dist/tables/table-utilities";
import {Helmet} from "react-helmet-async";

const EmployeesPage = () => {
    const [show, setShow] = useState(false)
    const tableRef = useRef<PaginatedTableComponentHandles | null>(null);

    const handleEmployeeCreated = async () => {
        tableRef?.current?.loadData()
        setShow(false)
    }

    const statusBadge = (status: string) =>  (
        <span className={`badge badge-${status === 'active' ? 'light-success': 'light-danger'} text-capitalize min-w-75px justify-content-center`}>
            {status}
        </span>
    )

    const tableActions = () => (
        <>
            <button className={'btn btn-primary'} onClick={() => setShow(true)}>Create Employee</button>
        </>
    )

    const employeeLink = (row: any) => {
        return <a className={'text-white'} href={`/employees/${row.id}`}>{row.fullName}</a>
    }

    const headers: TableHeader[] = [
        {key: 'fullName', label: 'Name', format: (cell) => employeeLink(cell.row)},
        {key: 'email', label: 'Email'},
        {key: 'createdAt', label: 'Joined Date', format: (cell) => TableUtilities.formatDate(cell.item)},
        {key: 'status', label: 'Status', format: (cell) => statusBadge(cell.item)},
    ]

    return (
        <Card className='pt-13' title={''}>
            <PaginatedTable ref={tableRef} className={''} headers={headers} dataUrl='/api/v1/user/employee/all' dataProperty='employees' filterLocally={true} actions={tableActions()} paginate={true} />
            <CreateEmployeeModal show={show} onClose={() => setShow(false)} onEmployeeCreated={handleEmployeeCreated} />
        </Card>
    )
}


const EmployeesWrapper = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.EMPLOYEES'})}</PageTitle>
            <Helmet>
                <title>{intl.formatMessage({id: 'MENU.EMPLOYEES'})} - Bind</title>
            </Helmet>
            <EmployeesPage/>
        </>
    )
}


export default EmployeesWrapper