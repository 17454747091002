import axios from "axios";
import {ProductEventType} from "consign-dash/src/app/modules/inventory/services/inventory.service";

const InventoryService = {
    fetchInventory: async (): Promise<InventoryItem[]> => {
        const response = await axios.get('/api/v1/item/search')

        return response.data.employees
    },
    fetchLocations: async (): Promise<Location[]> => {
        const response = await axios.get('/api/v1/location/all')

        return response.data.locations
    },
    fetchItem: async (itemId: string): Promise<InventoryItem> => {
        const response = await axios.get(`/api/v1/item/view/${itemId}`)

        return response.data;
    },
    acceptItems: async (itemIds: string[]) => {
        return await axios.patch('/api/v1/item/accept', {
            'item_id': itemIds
        })
    },
    completeWithdrawalForItems: async (itemIds: string[]) => {
        return await axios.patch('/api/v1/withdraw/complete', {
            'item_id': itemIds
        })
    },
    declineWithdrawalForItems: async (itemIds: string[]) => {
        return await axios.patch('/api/v1/withdraw/decline', {
            'item_id': itemIds,
        })
    },
    fetchItemAvailability: async (itemId: string) : Promise<VariantAvailability[]> => {
        const response = await axios.get(`api/v1/item/view/product/${itemId}/availability`)

        return response.data.sizes;
    },
    fetchItemSizeAvailability: async (productId: string, size: string, shape: string) : Promise<ItemPrice> => {
        const response = await axios.post('api/v1/item/view/product/size/availability', {
            'product_id': productId,
            'size': size,
            'shape': shape
        })

        return response.data;
    },
    declineItems: async (itemIds: string[], reason: string) => {
        return await axios.patch('/api/v1/item/decline', {
            'item_id': itemIds,
            'reason': reason
        })
    }
}

export default InventoryService;

export enum InventoryItemStatus {
    All = 'all',
    Active = 'active',
    Awaiting = 'awaiting_item',
    WithdrawalRequested = 'withdrawal_requested',
    InQueue = 'in_queue',
    Accepted = "accepted",
    Sold = "sold",
    Withdrawn = "withdrawn",
}

export interface InventoryItem {
    id: string,
    createdAt: string,
    size: string,
    price: number,
    pricePaid: number
    payoutAmount: number,
    profitAmount: number,
    shape: string,
    status: InventoryItemStatus,
    storeOwned: boolean,
    product: Product,
    location: Location,
    user: User
    event: InventoryItemEvent[]
}

interface InventoryItemEvent {
    id:string;
    createdAt: string;
    title: string;
    description: string;
    eventType: ProductEventType;
    user?: ProductUserSimple
}

interface ProductUserSimple {
    id: string
    fullName: string
    userType: string
}


interface Product {
    id: string,
    createdAt: string,
    title: string,
    brand: string,
    sku: string,
    colorway: string,
    category: string,
    imageUrl: string,
}

export interface Location {
    id: string,
    name: string,
    addressOne: string,
    addressTwo: string,
    zipCode: string,
    city: string,
    state: string,
    country: string,
    shopifyLocationId: string
}

interface User {
    id: string,
    firstName: string,
    lastName: string,
    userType: string,
    fullName: string,
}


export interface ItemPrice {
    price: number | null
}


export interface VariantAvailability {
    size: string
    price?: number
}
