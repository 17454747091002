import React from "react";
import {PaginatedTable, TableHeader} from "components";
import Card from "../../modules/dashboard/dashboard/components/Card";
import {useIntl} from "react-intl";
import {PageTitle} from "../../../_metronic/layout/core";
import TableUtilities from "components/dist/tables/table-utilities";
import {useLocation, useParams, useSearchParams} from "react-router-dom";
import {Helmet} from "react-helmet-async";

const LabelsPage = () => {
    const tableActions = (rowData: any, text: string | undefined = undefined) => {
        return (
            <a className='btn btn-link' href={rowData.url} target='_blank'>
                {text ? text : <i className="fa-solid fa-download text-primary"></i>}
            </a>
        )
    }

    const link = (rowData: any, text: string) => {
        return (
            <a className='text-white' href={rowData.url} target='_blank'>
                {text}
            </a>
        )
    }

    const headers: TableHeader[] = [
        {
            key: 'title',
            label: 'Name',
            rowClassName: 'min-w-125px',
            format: (cell) => link(cell.row, cell.item)
        },
        {key: 'createdAt', label: 'Created At', format: (cell) => TableUtilities.formatDate(cell.item)},
        {key: 'actions', label: '', format: (cell) => tableActions(cell.row), cellClassName: 'text-end'}
    ]

    return (
        <>
            <Card className={'p-5'} title=''>
                <PaginatedTable
                    className={'mt-5'}
                    headers={headers}
                    method={'post'}
                    dataUrl={'/api/v1/label/search'}
                    filters={{'label_type': 'product'}}
                    filterLocally={false}
                    paginate={true}
                />
            </Card>
        </>
    )
}

const LabelsWrapper = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.PRODUCT_LABELS'})}</PageTitle>
            <Helmet>
                <title>{intl.formatMessage({id: 'MENU.PRODUCT_LABELS'})} - Bind</title>
            </Helmet>
            <LabelsPage/>
        </>
    )
}


export default LabelsWrapper