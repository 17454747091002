import {toast} from "react-hot-toast";
import axios from "axios";

const ConsignorService = {
    disableConsignor: async(userId: string)=> {
        toast.loading('Please wait...', {id: 'disableConsignors'})

        try {
            const response = await axios.post('/api/v1/user/consignor/disable', {
                'user_id': userId
            })

            toast.success(response.data.message, {
                id: 'disableConsignors',
                duration: 8000
            })
        } catch (e: any) {
            toast.error('Oops something went wrong. Please try again.', {
                id: 'disableConsignors',
                duration: 5000
            })

            throw e;
        }
    },
    disableConsignors: async(userIds: string[])=> {
        toast.loading('Please wait...', {id: 'disableConsignors'})

        try {
            const response = await axios.post('/api/v1/user/consignor/disable', {
                'user_id': userIds
            })

            toast.success(response.data.message, {
                id: 'disableConsignors',
                duration: 8000
            })
        } catch (e: any) {
            toast.error('Oops something went wrong. Please try again.', {
                id: 'disableConsignors',
                duration: 5000
            })

            throw e;
        }
    },

}

export default ConsignorService;