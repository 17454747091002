import React, {useState} from "react";
import {Button, Modal, Form, Row, Col} from "react-bootstrap";
import {toast, Toaster} from "react-hot-toast";
import EmployeesService from "../employees.service";

type Props = {
    show: boolean,
    onClose: () => void,
    onEmployeeCreated?: () => void
}

const CreateEmployeeModal: React.FC<Props> = ({show, onClose, onEmployeeCreated}) => {
    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')

    const handleClose = () => onClose();

    const resetForm = () => {
        setEmail('')
        setFirstName('')
        setLastName('')
    }

    const createEmployee = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            await EmployeesService.createEmployee(email, firstName, lastName)

            resetForm()

            if (onEmployeeCreated) {
                onEmployeeCreated();
            }
        } catch (e: any) {
            toast.error(e.response.data.details.message, {
                id: 'createEmployee',
                duration: 5000
            })
        }
    };

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton className={'border-0 pb-0'}>
                </Modal.Header>
                <Modal.Body className='pt-10'>
                    <h2 className='text-center mb-10 fw-bolder fs-1'>Create Store Employee</h2>

                    <Form className='mt-10' onSubmit={createEmployee}>
                        <Form.Group className="mb-2 p-4" controlId="exampleForm.ControlInput1">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control value={email}
                                          onChange={(event) => setEmail(event.target.value)}
                                          required={true}
                                          className='form-control-solid'
                                          type="email"
                                          placeholder="name@example.com"
                            />
                        </Form.Group>
                        <Row className="mb-2 p-4">
                            <Form.Group as={Col} controlId="firstName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control value={firstName}
                                              onChange={(event) => setFirstName(event.target.value)}
                                              required={true}
                                              className='form-control-solid'
                                              type="text"
                                              placeholder="John"
                                />
                            </Form.Group>

                            <Form.Group as={Col} controlId="lastName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control value={lastName}
                                              onChange={(event) => setLastName(event.target.value)}
                                              required={true}
                                              className='form-control-solid'
                                              type="text"
                                              placeholder="Doe"
                                />
                            </Form.Group>
                        </Row>

                        <Form.Group className="px-4">
                            <Button variant="primary" className='w-100 max-w-100 mt-10 mb-10' type='submit'>
                                Submit
                            </Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default CreateEmployeeModal
