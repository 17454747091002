/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon} from "../../../../../_metronic/helpers";
import {Dropdown1} from "../../../../../_metronic/partials";

type Props = {
    title: string,
    subtitle: string,
    value: string,
    iconName: string,
    type: string
}

const ItemOwnership: React.FC<Props> = ({iconName, title, subtitle, value, type = 'success'}) => {
    return (
        <div className={`d-flex align-items-center bg-light-${type} rounded p-5 mb-7`}>
            {/* begin::Icon */}
            <span className={`text-${type} me-5`}>
            <KTIcon iconName={iconName} className={`text-${type} fs-1 me-5`}/>
          </span>
            {/* end::Icon */}
            {/* begin::Title */}
            <div className='flex-grow-1 me-2'>
                <a className='fw-bold text-gray-800 fs-6'>
                    {title}
                </a>
                <span className='text-muted fw-semibold d-block'>{subtitle}</span>
            </div>
            {/* end::Title */}
            {/* begin::Lable */}
            <span className={`fw-bold text-${type} py-1`}>{value}</span>
            {/* end::Lable */}
        </div>
    )
}

export {ItemOwnership}
