import {useIntl} from "react-intl";
import {PageTitle} from "../../../_metronic/layout/core";
import React, {useState} from "react";
import {PaginatedTable, TableHeader} from "components";
import {Button, Card} from "react-bootstrap";
import CreateLocationModal from "../../modules/dashboard/locations/components/CreateLocationModal";
import {Location} from "../../modules/dashboard/inventory/inventory.service";
import {Helmet} from "react-helmet-async";


const LocationPage = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState<Location | undefined>(undefined);
    const [tableKey, setTableKey] = useState<number>(568);

    const headers: TableHeader[] = [
        {key: 'name', label: 'Name', cellClassName: 'text-white'},
        {key: 'addressOne', label: 'Street Address'},
        {key: 'zipCode', label: 'Zip Code'},
        {key: 'city', label: 'City'},
        {key: 'state', label: 'State'},
        {key: 'id', label: 'Actions', format: (cell) => rowActions(cell.row)},
    ]

    const onLocationChange = () => {
        setTableKey(tableKey * 56);
        setSelectedLocation(undefined)
    }

    const updateLocation = (location: Location) => {
        setSelectedLocation(location)
        setShowModal(true)
    }

    const openModal = () => {
        setSelectedLocation(undefined)
        setShowModal(true)
    }

    const rowActions = (location: Location) => {
        return <Button variant='link' className='link-primary' onClick={() => updateLocation(location)} >Update</Button>
    }

    const tableActions = () => {
        return <Button variant='primary' onClick={() => openModal()}>
            Create Location
        </Button>
    }

    return (
        <>
            <Card>
                <Card.Body>
                    <PaginatedTable
                        key={tableKey}
                        headers={headers}
                        dataProperty={'locations'}
                        dataUrl={'/api/v1/location/all'}
                        className={''}
                        actions={tableActions()}
                        filterLocally={true}
                    />
                </Card.Body>
            </Card>

            <CreateLocationModal
                location={selectedLocation}
                onLocationChange={onLocationChange}
                show={showModal}
                onClose={() => setShowModal(false)}
            />
        </>
    )
}
const LocationWrapper = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.LOCATIONS'})}</PageTitle>
            <Helmet>
                <title>{intl.formatMessage({id: 'MENU.LOCATIONS'})} - Bind</title>
            </Helmet>
            <LocationPage/>
        </>
    )
}

export default LocationWrapper;