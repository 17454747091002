import {FormattedDateTimeRange, useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {MixedWidget6, StatisticsWidgetBasic} from '../../../_metronic/partials/widgets'
import React, {useEffect, useRef, useState} from "react";
import DashboardService, {
    Allocation,
    Analytics,
    AnalyticsData, DashboardOverview,
} from "../../modules/dashboard/dashboard/dashboard.service";
import {Card, Col, Row, Table} from "react-bootstrap";
import TableUtilities from "components/dist/tables/table-utilities";
import {ItemOwnership} from "../../modules/dashboard/dashboard/components/ItemOwnership";
import {Helmet} from "react-helmet-async";

interface AnalyticsPeriod {
    title: string
    data: AnalyticsData
    period?: boolean
}

const DashboardPage = () => {
    const [analyticsMappings, setAnalyticsMapping] = useState<Array<AnalyticsPeriod>>([]);
    const [dashboardOverview, setDashboardOverview] = useState<DashboardOverview | undefined>(undefined);
    const [allocation, setAllocation] = useState<Allocation | undefined>(undefined);

    const isSameDay = (date1: Date, date2: Date) => {
        return date1.getDate() === date2.getDate() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getFullYear() === date2.getFullYear();
    }


    const formatDateRange = (startDate: Date, endDate: Date) => {
        const month = startDate.toLocaleString('default', { month: 'long' });
        const startDay = startDate.getDate();
        const endDay = endDate.getDate();
        const year = startDate.getFullYear();

        if (isSameDay(startDate, endDate)) {
            return `${month} ${startDay}, ${year}`;
        } else {
            return `${month} ${startDay} - ${endDay}, ${year}`;
        }
    }

    const fetchAnalytics = async () => {
        const analytics = await DashboardService.fetchAnalytics();

        setAnalyticsMapping([
            {title: 'Daily', data: analytics.daily, period: true},
            {title: 'Weekly', data: analytics.weekly},
            {title: 'Month to date', data: analytics.monthly},
        ])
    }

    const fetchOverview = async () => {
        setDashboardOverview(await DashboardService.fetchOverview())
    }

    const fetchAllocation = async () => {
        setAllocation(await DashboardService.fetchAllocation());
    }

    const fetchData = async () => {
        await fetchAnalytics()
        await fetchOverview();
        await fetchAllocation();
    }

    useEffect(() => {
        (async () => {
            await fetchData();
        })()
    }, [])


    return (
        <>
            {/*begin::Row*/}
            <Row className='g-5 g-xl-8'>
                <div className='col-xl-4'>
                    <StatisticsWidgetBasic
                        className='card-xl-stretch mb-5 mb-xl-8 bg-primary'
                        svgIcon='abstract-29'
                        color=''
                        iconColor='black'
                        title={dashboardOverview?.overview.totalItems.toString() ?? '-'}
                        titleColor='white'
                        description='Total Items'
                        descriptionColor='white'
                        target='/inventory/discover'
                    />
                </div>

                <div className='col-xl-4'>
                    <StatisticsWidgetBasic
                        className='card-xl-stretch mb-5 mb-xl-8'
                        svgIcon='profile-user'
                        color=''
                        iconColor=''
                        title={dashboardOverview?.overview.awaitingItems.toString() ?? '-'}
                        titleColor='white'
                        description='Awaiting Items'
                        descriptionColor='white'
                        target='/inventory/inbound'
                    />
                </div>

                <div className='col-xl-4'>
                    <StatisticsWidgetBasic
                        className='card-xl-stretch mb-5 mb-xl-8'
                        svgIcon='parcel-tracking'
                        color=''
                        iconColor=''
                        title={dashboardOverview?.overview.awaitingPayouts.toString() ?? '-'}
                        titleColor='white'
                        description='Awaiting Payouts'
                        descriptionColor='white'
                        target='/payouts/awaiting'
                    />
                </div>
            </Row>
            {/* end::Row */}

            <Row className='g-5 g-xl-8 mb-8'>
                {analyticsMappings.map(i => (
                    <Col sm={4}>
                        <Card>
                            <Card.Header className='d-block pt-4'>
                                <h5>{i.title}</h5>
                                <p className='text-gray-500 fw-semibold'>
                                    {formatDateRange(new Date(i.data.startDate), new Date(i.data.endDate))}
                                </p>
                            </Card.Header>

                            <Card.Body>
                                <Row className='row'>
                                    <Col>
                                        <h3 className='text-gray-500 fw-bold fs-7'>Revenue</h3>
                                        <span
                                            className='fs-5 fw-bold'>{TableUtilities.formatCurrencyCents(i.data.totalRevenue)}</span>
                                    </Col>

                                    <Col>
                                        <h3 className='text-gray-500 fw-bold fs-7'>Orders</h3>
                                        <span
                                            className='fs-5 fw-bold text-gray-600'>{i.data.totalOrders}</span>
                                    </Col>
                                </Row>

                                <Row className='d-flex justify-content-between mt-10'>
                                    <Col>
                                        <h3 className='text-gray-500 fw-bold fs-7'>Store Item Profit</h3>
                                        <span className='fs-5 fw-bold'>
                                            {TableUtilities.formatCurrencyCents(i.data.storeItemProfit)}
                                        </span>
                                    </Col>

                                    <Col>
                                        <h3 className='text-gray-500 fw-bold fs-7'>Store Items</h3>
                                        <span className='fs-5 fw-bold text-gray-600'>{i.data.storeItems}</span>
                                    </Col>
                                </Row>

                                <Row className='d-flex justify-content-between mt-10'>
                                    <Col>
                                        <h3 className='text-gray-500 fw-bold fs-7'>Consigner Fee Profit</h3>
                                        <span className='fs-5 fw-bold'>
                                            {TableUtilities.formatCurrencyCents(i.data.consignorItemProfit)}
                                        </span>
                                    </Col>

                                    <Col>
                                        <h3 className='text-gray-500 fw-bold fs-7'>Consigner Items</h3>
                                        <span className='fs-5 fw-bold text-gray-600'>{i.data.consignorItems}</span>
                                    </Col>
                                </Row>

                                <Row className='d-flex justify-content-between mt-10'>
                                    <Col>
                                        <h3 className='text-gray-500 fw-bold fs-7'>Total Profit</h3>
                                        <span className='fs-5 fw-bold'>
                                            {TableUtilities.formatCurrencyCents(i.data.totalProfit)}
                                        </span>
                                    </Col>

                                    <Col>
                                        <h3 className='text-gray-500 fw-bold fs-7'>Total Items</h3>
                                        <span className='fs-5 fw-bold text-gray-600'>{i.data.totalItems}</span>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>

            <Row className='g-5 g-xl-8'>
                <Col sm={8}>
                    <Card className={'h-100'}>
                        <Card.Header className='border-bottom-0 pt-4'>
                            <h3>Inventory Overview</h3>
                        </Card.Header>

                        <Card.Body>
                            <Table className='table-row-dashed'>
                                <thead>
                                <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                    <th>Location</th>
                                    <th>Store Items</th>
                                    <th>Consigner Items</th>
                                    <th>Total Items</th>
                                </tr>
                                </thead>

                                <tbody>
                                {allocation?.allocation.map(a => (
                                    <tr className='text-gray-600 fw-bold'>
                                    <td className='text-gray-800'>{a.locationName}</td>
                                    <td>{a.storeOwnedItems}</td>
                                    <td>{a.consignorOwnedItems}</td>
                                    <td>{a.totalItems}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>

                <Col sm={4}>
                    <Card>
                        <Card.Header className='pt-4 border-bottom-0 mb-0'>
                            <h3>Ownership</h3>
                        </Card.Header>

                        <Card.Body className='pt-0'>
                            <ItemOwnership
                                title={'Store Owned'}
                                subtitle={'Assets'}
                                value={dashboardOverview?.ownership.storeOwnedItems.toString() ?? '0'}
                                iconName={'up-square'} type='success'
                            />

                            <ItemOwnership
                                title={'Consigner Owned'}
                                subtitle={'Liabilities'}
                                value={dashboardOverview?.ownership.consignorOwnedItems.toString() ?? '0'}
                                iconName={'down-square'} type='danger'
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

const DashboardWrapper = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
            <Helmet>
                <title>Dashboard - Bind</title>
            </Helmet>
            <DashboardPage/>
        </>
    )
}

export {DashboardWrapper}
