import Card from "../../modules/dashboard/dashboard/components/Card";
import React, {useState} from "react";
import InventoryService, {InventoryItem, InventoryItemStatus} from "../../modules/dashboard/inventory/inventory.service";
import {useIntl} from "react-intl";
import {PageTitle} from "../../../_metronic/layout/core";
import InventoryTable from "../../modules/dashboard/inventory/components/InventoryTable";
import {Button} from "react-bootstrap";
import {toast, Toaster} from "react-hot-toast";
import {Helmet} from "react-helmet-async";
import { ConfirmModal } from "components";

const PendingInventoryPage = () => {
    const [selectedItems, setSelectedItems] = useState<InventoryItem[]>([]);
    const [childKey, setChildKey] = useState<number>(515);
    const [completeMessageVisible, setCompleteMessageVisible] = useState<boolean>(false);
    const [declineMessageVisible, setDeclineMessageVisible] = useState<boolean>(false);


    const onCompleteWithdrawItems = async () => {
        if(selectedItems.length === 0) return;

        toast.loading('Please wait...', {id: 'withdrawItems'})

        setCompleteMessageVisible(false);

        try {
            const response = await InventoryService.completeWithdrawalForItems(selectedItems.map(s => s.id))

            // Update the child key to force a re-render/re-fetch of the table data.
            setChildKey(childKey * 89);

            toast.success(response.data.message, {
                id: 'withdrawItems',
                duration: 8000
            })
        } catch(e: any) {
            toast.error(e.response.data.details.message, {
                id: 'withdrawItems',
                duration: 5000
            })
        }
    }

    const onDeclineWithdrawItems = async () => {
        if(selectedItems.length === 0) return;

        toast.loading('Please wait...', {id: 'withdrawItems'})

        setDeclineMessageVisible(false);    

        try {
            const response = await InventoryService.declineWithdrawalForItems(selectedItems.map(s => s.id))

            // Update the child key to force a re-render/re-fetch of the table data.
            setChildKey(childKey * 89);

            toast.success(response.data.message, {
                id: 'withdrawItems',
                duration: 8000
            })
        } catch(e: any) {
            toast.error(e.response.data.details.message, {
                id: 'withdrawItems',
                duration: 5000
            })
        }
    }

    const onItemsSelected = (items: InventoryItem[] ) => {
        setSelectedItems(items)
    }

    const actions = (
        <>
            <div className='d-flex align-items-center gap-3'>
                {selectedItems.length > 0 && (
                    <>
                        <span className='fw-bold'>
                            {selectedItems.length} Items Selected
                        </span>
                    </>
                )}
                <Button variant={"secondary"} className='ms-3' onClick={() => setDeclineMessageVisible(true)}>
                    Decline Withdraw
                </Button>
                <Button variant={"primary"} className='ms-3' onClick={() => setCompleteMessageVisible(true)}>
                    Complete Withdraw
                </Button>
            </div>
        </>
    )

    return (
        <>
            <Card className={'p-5'} title=''>
                <InventoryTable tableKey={childKey} selectable={true} actions={actions}
                                statusFilter={InventoryItemStatus.WithdrawalRequested} onSelected={onItemsSelected}/>
            </Card>

            <ConfirmModal
                visible={completeMessageVisible}
                onConfirm={onCompleteWithdrawItems}
                message={`Are you sure you want to complete the withdrawal for the selected items?`}
                onCancel={() => setCompleteMessageVisible(false)}
            />

            <ConfirmModal
                visible={declineMessageVisible}
                onConfirm={onDeclineWithdrawItems}
                message={`Are you sure you want to decline the withdrawal for the selected items?`}
                onCancel={() => setDeclineMessageVisible(false)}
            />
        </>
    )
}

const PendingInventoryWrapper = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.PENDING_WITHDRAWALS'})}</PageTitle>
            <Helmet>
                <title>{intl.formatMessage({id: 'MENU.PENDING_WITHDRAWALS'})} - Bind</title>
            </Helmet>
            <PendingInventoryPage/>
        </>
    )
}

export default PendingInventoryWrapper