import {useIntl} from "react-intl";
import {PageTitle} from "../../../_metronic/layout/core";
import React from "react";
import {Button, Card, Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import {CatalogSearch} from "components";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet-async";


const CatalogPage = () => {
    return (
        <>
            <Row className='mh-100 justify-content-center'>
                <Col md={'8'} xs={'12'}>
                    <CatalogSearch
                        footer={(
                            <>
                                {/*<Button variant={'secondary'} size={'sm'}>Organization Products</Button>*/}
                                <Link to='/catalog/create' className={'ms-5 btn btn-primary btn-sm'}>
                                    Create New Product
                                </Link>
                            </>
                        )}
                    />
                </Col>
            </Row>
        </>
    )
}

const CatalogWrapper = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.CATALOG'})}</PageTitle>
            <Helmet>
                <title>{intl.formatMessage({id: 'MENU.CATALOG'})} - Bind</title>
            </Helmet>
            <CatalogPage/>
        </>
    )
}


export default CatalogWrapper