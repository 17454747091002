import {useIntl} from "react-intl";
import {PageTitle} from "../../../_metronic/layout/core";
import React, {useState} from "react";
import {Category, PaginatedTable, TableHeader} from "components";
import {Button, Card} from "react-bootstrap";
import CreateCategoryModal from "../../modules/dashboard/categories/components/CreateCategoryModal";
import {Helmet} from "react-helmet-async";

const CategoriesPage = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedCategory, setSelectedCategory]  = useState<Category | undefined>(undefined);
    const [tableKey, setTableKey] = useState<number>(561);

    const headers: TableHeader[] = [
        {key: 'name', label: 'Name', cellClassName: 'text-white'},
        {key: 'categoryDetail[0].weight', label: 'Weight (grams)'},
        {key: 'id', label: 'Actions', format: (cell) => rowActions(cell.row)},
    ]

    const onCategoryChange = () => {
        setTableKey(tableKey * 56);
    }

    const updateCategory = (category: Category) => {
        setSelectedCategory(category);
        setShowModal(true)
    }

    const openModal = () => {
        setSelectedCategory(undefined)
        setShowModal(true)
    }

    const rowActions = (categoryData: any) => {
        const category = {
            name: categoryData.name,
            weight: categoryData.categoryDetail[0].weight,
            id: categoryData.categoryDetail[0].id
        } as Category

        return <Button variant='link' className='link-primary' onClick={() => updateCategory(category)} >Update</Button>
    }

    const tableActions = () => {
        return <Button variant='primary' onClick={() => openModal()}>
            Create Category
        </Button>
    }

    return (
        <>
            <Card>
                <Card.Body>
                    <PaginatedTable
                        key={tableKey}
                        headers={headers}
                        dataProperty={'categories'}
                        dataUrl={'/api/v1/category/all'}
                        className={''}
                        actions={tableActions()}
                        filterLocally={true}
                    />
                </Card.Body>
            </Card>

            <CreateCategoryModal
                category={selectedCategory}
                show={showModal}
                onClose={() => setShowModal(false)}
                onCategoryChange={onCategoryChange}
            />
        </>
    )
}
const CategoriesWrapper = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.CATEGORIES'})}</PageTitle>
            <Helmet>
                <title>{intl.formatMessage({id: 'MENU.CATEGORIES'})} - Bind</title>
            </Helmet>
            <CategoriesPage/>
        </>
    )
}

export default CategoriesWrapper;